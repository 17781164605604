import React from 'react';
import { Form } from 'antd';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

const PhoneNumberInput = ({ name = "phoneNumber", label = "Phone Number", required = true }) => {
    return (
        <Form.Item
            name={name}
            label={label}
            rules={[
                {
                    required,
                    message: 'Please input your phone number',
                },
                {
                    validator: (_, value) => {
                        if (!value) return Promise.resolve();
                        if (value.length < 8) {
                            return Promise.reject('Phone number is too short');
                        }
                        return Promise.resolve();
                    }
                }
            ]}
        >
            <PhoneInput
                country={'sa'} // Default to Saudi Arabia
                enableSearch={true}
                searchPlaceholder="Search country"
                inputStyle={{
                    width: '100%',
                    height: '32px',
                    fontSize: '14px'
                }}
                buttonStyle={{
                    borderRadius: '6px 0 0 6px',
                    borderRight: 'none'
                }}
                dropdownStyle={{
                    width: '350px'
                }}
                searchStyle={{
                    width: '90%',
                    margin: '5px'
                }}
                containerStyle={{
                    width: '100%'
                }}
            />
        </Form.Item>
    );
};

export default PhoneNumberInput;
