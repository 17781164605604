import React from 'react';
import Navbar from './components/Navbar';
import Home from './components/Home';
import Services from './components/Services';
import About from './components/About';
import GulfJobApplicationForm from './components/ApplyForm';
import FooterComponent from './components/Footer';
import './App.css';
import ContactUsPage from './components/ContactUs';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import AdminLogin from './components/Admin/LoginPage';
import UserManagementDashboard from './components/Admin/Dashboard';
import Sidebar from './components/Admin/Sidebar';
import Profile from './components/Admin/Profile';
import Interviews from './components/Admin/Interviews';
import ProtectedRoute from './components/ProtectedRoute';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={
          <div className="App">
            <Navbar />
            <Home />
            <About />
            <Services />
            <ContactUsPage />
            <GulfJobApplicationForm />
            <FooterComponent />
          </div>
        } />
        <Route path="/admin" element={<AdminLogin />} />
        <Route path="/admin/dashboard" element={
          <ProtectedRoute>
            <div>
              <Sidebar />
              <UserManagementDashboard />
            </div>
          </ProtectedRoute>
        } />
        <Route path="/admin/Interviews" element={
          <ProtectedRoute>
            <div>
              <Sidebar />
              <Interviews />
            </div>
          </ProtectedRoute>
        } />
        <Route path="/admin/profile" element={
          <ProtectedRoute>
            <div>
              <Sidebar />
              <Profile />
            </div>
          </ProtectedRoute>
        } />
      </Routes>
    </Router>

  );
}

export default App;
