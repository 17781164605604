import React, { useState, useEffect } from 'react';
import {message, Spin } from 'antd';
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import "./style.css";
import { apiBaseUrl } from '../../../constants/apiDetails';



const PayPalOpt = ({ paymentAmount = "4.00", userID }) => {
    const [paypalConfig, setPaypalConfig] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    
    const CLIENT_ID = "Ack5dY5fKH_aIM2Mp1CW5jHiLZ88_gkzKYRGquRSI4X4fiYKZFP4mAOxWzy4hx3oPnusl9K5sxhF6Eqo";

    const fetchClientToken = async () => {
        try {
            const response = await fetch(`${apiBaseUrl}/get-paypal-client-token`);
            const data = await response.json();
            
            if (data.status === 'success' && data.clientToken) {
                setPaypalConfig({
                    "client-id": CLIENT_ID,
                    currency: "USD",
                    intent: "capture",
                    "data-client-token": data.clientToken,
                    components: "buttons",
                    "enable-funding": "paylater,venmo,card",
                    "disable-funding": "credit",
                    vault: true,
                });
                console.log("Client token received:", data.clientToken);
            } else {
                throw new Error('Invalid client token received');
            }
        } catch (error) {
            console.error('Error fetching client token:', error);
            message.error('Failed to initialize payment system');
        }
    };

    useEffect(() => {
        const initializePayment = async () => {
            setIsLoading(true);

            try {
                await fetchClientToken();
            } catch (error) {
                console.error('Error initializing payment:', error);
            } finally {
                setIsLoading(false);
            }
        };

        initializePayment();
    }, []);

    if (isLoading) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', padding: '40px' }}>
                <Spin size="large" tip="Loading payment options..." />
            </div>
        );
    }

    if (!paypalConfig) {
        return null;
    }

    const handlePaypalApprove = async (data, actions) => {
        try {
            const details = await actions.order.capture();
            message.success('Payment completed successfully!');
            await handlePaymentSuccess(details);
        } catch (err) {
            message.error('Payment failed');
            console.error('Payment error:', err);
        }
    };

    const createPaypalOrder = (data, actions) => {
        return actions.order.create({
            purchase_units: [{
                amount: {
                    value: paymentAmount,
                    currency_code: "USD"
                },
                description: "Application Fee",
                custom_id: userID
            }]
        });
    };

    const handlePaymentSuccess = async (details) => {
        try {
            const response = await fetch(`${apiBaseUrl}/paypal/payment/success`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    user_id: userID,
                    transaction_ref: details.id,
                    payment_details: details
                }),
            });
            
            if (!response.ok) {
                throw new Error('Failed to update payment status');
            }
        } catch (error) {
            console.error('Error updating payment status:', error);
        }
    };

    // TODO: Update the payment status in the database or backend
    const handlePaymentFailure = async (error) => {
        try {
            const response = await fetch(`${apiBaseUrl}/paypal/payment/failed`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    user_id: userID,
                }),
            });
            
            if (!response.ok) {
                throw new Error('Failed to update payment status');
            }
        } catch (error) {
            console.error('Error updating payment status:', error);
        }
    }

    // TODO: Update the payment status in the database or backend
    // const handlePaymentCancel = async () => {
    //     try {
    //         const response = await fetch(`${apiBaseUrl}/paypal/payment/cancel`, {
    //             method: 'POST',
    //             headers: {
    //                 'Content-Type': 'application/json',
    //             },
    //             body: JSON.stringify({
    //                 user_id: userID,
    //             }),
    //         });
            
    //         if (!response.ok) {
    //             throw new Error('Failed to update payment status');
    //         }
    //     } catch (error) {
    //         console.error('Error updating payment status:', error);
    //     }
    // }

    return (
        <PayPalScriptProvider options={paypalConfig}>
            <div style={{ width: '100%', minHeight: '200px' }}>
                <PayPalButtons
                    createOrder={createPaypalOrder}
                    onApprove={handlePaypalApprove}
                    onError={(err) => {
                        message.error('Payment failed. Please try again.');
                        handlePaymentFailure(err);
                    }}
                    onCancel={() => {
                        message.info('Payment cancelled');
                    }}
                    style={{
                        layout: "vertical",
                        shape: "rect",
                        color: "gold",
                        height: 40
                    }}
                />
            </div>
        </PayPalScriptProvider>
    );
};

export default PayPalOpt;