import { useState, useEffect } from 'react';
import { Typography, Button } from 'antd';
import PaymentOptions from './PaymentOptions';


const { Title, Text } = Typography;

const PaymentStep = ({ userID }) => {
    const [currency, setCurrency] = useState('');
    const [paymentAmount, setPaymentAmount] = useState('');


    useEffect(() => {
        const fetchLocationAndRates = async () => {
            try {
                const baseAmount = 15; // 15 Riyals

                setCurrency('SAR (﷼)');
                setPaymentAmount(baseAmount.toFixed(2));

            } catch (error) {
                console.error('Error fetching location or exchange rates:', error);
                // Fallback to base currency in case of an error
                setCurrency('SAR');
                setPaymentAmount('15.00');
            }
        };

        fetchLocationAndRates();
    }, []);

    const [showPaymentOptions, setShowPaymentOptions] = useState(false);


    const handlePayment = () => {
        // Toggle visibility of payment options
        console.log(userID);
        setShowPaymentOptions(true);
    };

    return (
        <div>
            <Title level={4}>Payment</Title>
            <Text>
                To submit your application, you are required to pay a fee of <strong>{paymentAmount} {currency} = 4.00 USD </strong>.
                This ensures that applications are genuine and processed with priority.
            </Text>
            <br />
            <br />
            {!showPaymentOptions ? (
                <Button type="primary" onClick={handlePayment}>
                    Proceed to Pay
                </Button>
            ) : (
                console.log(userID),
                <PaymentOptions userID={userID} />
            )}
        </div>
    );
};

export default PaymentStep;