// List of positions available in Gulf Development Contracting
export const positions = [
    // Engineering Positions
    {
        category: "Engineering",
        roles: [
            "Civil Engineer",
            "Structural Engineer",
            "Mechanical Engineer",
            "Electrical Engineer",
            "Project Engineer",
            "Site Engineer",
            "Quality Control Engineer",
            "Safety Engineer",
            "Construction Engineer",
            "Design Engineer"
        ]
    },
    // Management Positions
    {
        category: "Management",
        roles: [
            "Project Manager",
            "Construction Manager",
            "Site Manager",
            "Operations Manager",
            "Quality Manager",
            "Safety Manager",
            "Contract Manager",
            "Procurement Manager",
            "Facility Manager"
        ]
    },
    // Technical Positions
    {
        category: "Technical",
        roles: [
            "Architect",
            "AutoCAD Technician",
            "Surveyor",
            "Quantity Surveyor",
            "Building Inspector",
            "Technical Supervisor",
            "MEP Coordinator",
            "BIM Specialist",
            "Draughtsman"
        ]
    },
    // Skilled Labor
    {
        category: "Skilled Labor",
        roles: [
            "Foreman",
            "Carpenter",
            "Electrician",
            "Plumber",
            "Mason",
            "Welder",
            "Heavy Equipment Operator",
            "Steel Fixer",
            "Painter"
        ]
    },
    // Administrative
    {
        category: "Administrative",
        roles: [
            "Administrative Assistant",
            "Document Controller",
            "HR Coordinator",
            "Accountant",
            "Logistics Coordinator",
            "Office Manager",
            "Secretary",
            "Data Entry Operator"
        ]
    }
].sort((a, b) => a.category.localeCompare(b.category));

// Function to get all positions as a flat array
export const getAllPositions = () => {
    return positions.reduce((acc, category) => {
        return [...acc, ...category.roles];
    }, []).sort();
};

// Function to get position options in Ant Design Select format
export const getPositionOptions = () => {
    return positions.map(category => ({
        label: category.category,
        options: category.roles.map(role => ({
            label: role,
            value: role
        }))
    }));
};

// Function to get position options as a flat array in Ant Design Select format
export const getFlatPositionOptions = () => {
    return getAllPositions().map(position => ({
        label: position,
        value: position
    }));
};
