import { apiBaseUrl } from '../constants/apiDetails';

const adminService = {
    getProfile: async () => {
        try {
            const token = localStorage.getItem('token');
            if (!token) {
                throw new Error('No authentication token found');
            }

            const response = await fetch(`${apiBaseUrl}/admin/profile`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });

            const result = await response.json();
            if (result.status === 'success') {
                return result.data;
            } else {
                throw new Error(result.message || 'Failed to fetch profile');
            }
        } catch (error) {
            console.error('Error fetching admin profile:', error);
            throw error;
        }
    },

    updateProfile: async (data) => {
        try {
            const token = localStorage.getItem('token');
            if (!token) {
                throw new Error('No authentication token found');
            }

            const response = await fetch(`${apiBaseUrl}/admin/update-profile`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(data)
            });

            const result = await response.json();
            if (result.status === 'success') {
                return result;
            } else {
                throw new Error(result.message || 'Failed to update profile');
            }
        } catch (error) {
            console.error('Error updating admin profile:', error);
            throw error;
        }
    },

    createAdmin: async (data) => {
        try {
            const token = localStorage.getItem('token');
            if (!token) {
                throw new Error('No authentication token found');
            }

            const response = await fetch(`${apiBaseUrl}/admin/create`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(data)
            });

            const result = await response.json();
            if (result.status === 'success') {
                return result;
            } else {
                throw new Error(result.message || 'Failed to create admin');
            }
        } catch (error) {
            console.error('Error creating admin:', error);
            throw error;
        }
    },

    listAdmins: async () => {
        try {
            const token = localStorage.getItem('token');
            if (!token) {
                throw new Error('No authentication token found');
            }

            const response = await fetch(`${apiBaseUrl}/admin/list`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });

            const result = await response.json();
            if (result.status === 'success') {
                return result.data;
            } else {
                throw new Error(result.message || 'Failed to fetch admins');
            }
        } catch (error) {
            console.error('Error fetching admins:', error);
            throw error;
        }
    },

    getInterviews: async (startDate, endDate) => {
        try {
            const token = localStorage.getItem('token');
            if (!token) {
                throw new Error('No authentication token found');
            }

            const response = await fetch(`${apiBaseUrl}/admin/interviews?startDate=${startDate}&endDate=${endDate}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });

            const result = await response.json();
            if (result.status === 'success') {
                return result;
            } else {
                throw new Error(result.message || 'Failed to fetch interviews');
            }
        } catch (error) {
            console.error('Error fetching interviews:', error);
            throw error;
        }
    }
};

export default adminService;
