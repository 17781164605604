import React, { useState } from 'react';
import { Form, Input, Button, Row, Col, Tag, Typography } from 'antd';

const { Title } = Typography;

const SkillsStep = ({ skills, setSkills }) => {
    const [skillInput, setSkillInput] = useState(""); // State for skill input

    // Handle adding a new skill
    const handleAddSkill = () => {
        if (skillInput.trim() !== "" && !skills.includes(skillInput.trim())) {
            setSkills([...skills, skillInput.trim()]); // Add skill to list
            setSkillInput(""); // Clear input field

            // Focus on the input field after adding a skill
            const inputField = document.querySelector('input[placeholder="Enter Skill"]');
            if (inputField) {
                inputField.focus();
            }

        }
    };

    // Handle removing a skill
    const handleRemoveSkill = (skill) => {
        setSkills(skills.filter((s) => s !== skill)); // Remove skill
    };

    return (
        <div>
            <Form>
                <Title level={4}>Skills:</Title>
                {/* Display the skills as tags */}
                <div style={{ marginTop: '16px', display: 'flex', flexWrap: 'wrap' }}>
                    {skills.map((skill, index) => (
                        <Tag
                            key={index}
                            closable
                            onClose={() => handleRemoveSkill(skill)}
                            style={{
                                marginBottom: '8px',
                                marginRight: '8px',
                                backgroundColor: '#f0f0f0',
                                cursor: 'pointer',
                            }}
                        >
                            {skill}
                        </Tag>
                    ))}
                </div>

                {/* Input and button to add a new skill */}
                <Row gutter={2}>
                    <Col xs={24} sm={18} md={18} lg={10}>
                        <Form.Item label="Enter Skill" required>
                            <Input
                                value={skillInput}
                                onChange={(e) => setSkillInput(e.target.value)} // Update the input field state
                                placeholder="Enter Skill"
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        handleAddSkill(); // Call the function to handle skill submission
                                        e.preventDefault(); // Prevent default form submission behavior
                                    }
                                }}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={5} align="bottom" style={{ marginTop: '20px' }}>
                    <Col xs={24} sm={6} md={6} lg={4}>
                        <Button
                            type="primary"
                            onClick={handleAddSkill} // Add skill when clicked
                            block
                        >
                            Add Skill
                        </Button>
                    </Col>
                </Row>
            </Form>
        </div>
    );
};

export default SkillsStep;