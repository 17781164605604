import axios from 'axios';
import { apiBaseUrl } from '../constants/apiDetails';

const userService = {
    getUsers: async (filters = {}, pagination = {}) => {
        try {
            const token = localStorage.getItem('token');
            if (!token) {
                throw new Error('No authentication token found');
            }

            // Clean up filters by removing empty values
            const cleanFilters = Object.fromEntries(
                Object.entries(filters).filter(([_, value]) => 
                    value !== undefined && 
                    value !== null && 
                    value !== ''
                )
            );

            // Ensure pageSize is a number and use the correct parameter name
            const params = {
                page: pagination.current || 1,
                pageSize: pagination.pageSize || 10,
                ...cleanFilters
            };

            console.log('Request params:', params); // Debug log

            const queryString = Object.entries(params)
                .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
                .join('&');

            const response = await fetch(`${apiBaseUrl}/fetch-all-users?${queryString}`, {
                method: 'GET',
                headers: { 
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });

            if (!response.ok) {
                throw new Error('Failed to fetch users');
            }

            const result = await response.json();
            console.log('Server response:', result); // Debug log
            
            if (result.status === 'success') {
                return {
                    data: result.data,
                    total: result.total,
                    currentPage: result.currentPage,
                    pageSize: params.pageSize // Return the page size we sent
                };
            } else {
                throw new Error(result.message || 'Failed to fetch users');
            }
        } catch (error) {
            console.error('Error fetching users:', error);
            throw error;
        }
    },

    updateUser: async (userId, userData) => {
        try {
            const token = localStorage.getItem('token');
            if (!token) {
                throw new Error('No authentication token found');
            }

            const response = await fetch(`${apiBaseUrl}/update-user`, {
                method: 'POST',
                headers: { 
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({
                    id: userId,
                    user_data: {...userData}
                })
            });

            if (!response.ok) {
                throw new Error('Failed to update user');
            }

            const data = await response.json();
            if (data.status !== "success") {
                throw new Error(data.message || 'Failed to update user');
            }

            return data;
        } catch (error) {
            console.error('Error updating user:', error);
            throw error;
        }
    },

    deleteUser: async (userId) => {
        try {
            const token = localStorage.getItem('token');
            if (!token) {
                throw new Error('No authentication token found');
            }

            const response = await fetch(`${apiBaseUrl}/delete-user`, {
                method: 'POST',
                headers: { 
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({
                    id: userId
                })
            });

            if (!response.ok) {
                throw new Error('Failed to delete user');
            }

            const data = await response.json();
            if (data.status !== "success") {
                throw new Error(data.message || 'Failed to delete user');
            }

            return data;
        } catch (error) {
            console.error('Error deleting user:', error);
            throw error;
        }
    },

    updateApplicationStatus: async (userId, status) => {
        try {
            const response = await axios.post(`${apiBaseUrl}/admin/application/update-status`, {
                userId,
                status
            }, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });
            return response.data;
        } catch (error) {
            console.error('Error updating application status:', error);
            throw error;
        }
    },

    scheduleInterview: async (interviewData) => {
        try {
            const response = await axios.post(`${apiBaseUrl}/admin/schedule-interview`, 
                interviewData,
                {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`
                    }
                }
            );
            return response.data;
        } catch (error) {
            console.error('Error scheduling interview:', error);
            throw error;
        }
    },

    getInterviews: async (startDate, endDate) => {
        try {
            const response = await axios.get(`${apiBaseUrl}/admin/interviews`, {
                params: { startDate, endDate },
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });
            return response.data;
        } catch (error) {
            console.error('Error fetching interviews:', error);
            throw error;
        }
    },

    sendPaymentConfirmationEmail: async (userId) => {
        try {
            const payload = {
                user_id: userId
            }
            const response = await axios.post(`${apiBaseUrl}/email-confirmation`, 
                payload
            );
            return response.data;
        } catch (error) {
            console.error('Error sending payment confirmation email:', error);
            throw error;
        }
    }, 
};

export default userService;
