import React from 'react';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

const AuthLoader = () => {
    const antIcon = <LoadingOutlined style={{ fontSize: 48, color: '#9b2231' }} spin />;

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100vh',
                backgroundColor: '#f0f2f5',
            }}
        >
            <Spin indicator={antIcon} />
            <h2 style={{ marginTop: 16, color: '#9b2231' }}>Authenticating...</h2>
        </div>
    );
};

export default AuthLoader;