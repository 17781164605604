// React Footer using Ant Design
import React from 'react';
import { Layout } from 'antd';

const { Footer } = Layout;

const FooterComponent = () => {
    return (
        <Footer style={{ textAlign: 'center' }}>
            Copyright © Gulf Development Contracting 2023.
            All Rights Reserved
        </Footer >
    );
}

export default FooterComponent;

