import React from 'react';
import { Navigate } from 'react-router-dom';
import { apiBaseUrl } from '../constants/apiDetails';
import { useNavigate } from 'react-router-dom';



const ProtectedRoute = ({ children }) => {
    const navigate = useNavigate();

    // Verify the token is an admin token
    const verifyToken = async () => {
        try {
            const response = await fetch(`${apiBaseUrl}/verify-token`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `${token}`,
                }
            });

            const data = await response.json();
            if (data.status === "success") {
                return children; // Render the protected content if admin
            } else {
                localStorage.removeItem('token');
                navigate('/admin');
            }
        } catch (error) {
            console.error('Token verification failed:', error);
            localStorage.removeItem('token');
            navigate('/admin');
        }
    };





    const token = localStorage.getItem('token'); // Check admin status from localStorage

    if (!token) {
        return <Navigate to="/admin" replace />; // Redirect to login if not admin
    }

    else {
        verifyToken(); // Verify the token is an admin token
    }






    return children; // Render the protected content if admin
};

export default ProtectedRoute;
