// List of nationalities commonly found in the Gulf region and worldwide
export const nationalities = [
    "Afghan",
    "Algerian",
    "American",
    "Bahraini",
    "Bangladeshi",
    "British",
    "Chinese",
    "Egyptian",
    "Emirati",
    "Filipino",
    "French",
    "German",
    "Indian",
    "Indonesian",
    "Iranian",
    "Iraqi",
    "Italian",
    "Japanese",
    "Jordanian",
    "Kuwaiti",
    "Lebanese",
    "Malaysian",
    "Moroccan",
    "Nepali",
    "Nigerian",
    "Omani",
    "Pakistani",
    "Palestinian",
    "Qatari",
    "Russian",
    "Saudi Arabian",
    "Singaporean",
    "South African",
    "South Korean",
    "Spanish",
    "Sri Lankan",
    "Sudanese",
    "Syrian",
    "Thai",
    "Turkish",
    "Yemeni"
].sort(); // Sorted alphabetically for easier selection

// Function to get nationality options in Ant Design Select format
export const getNationalityOptions = () => {
    return nationalities.map(nationality => ({
        label: nationality,
        value: nationality
    }));
};
