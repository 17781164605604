import React, { useState, useEffect } from 'react';
import {
    Card,
    Calendar,
    Badge,
    Row,
    Col,
    List,
    Typography,
    Space,
    Tag,
    Button,
    Modal,
    Timeline,
    Spin,
    Tooltip,
    Empty,
    Radio,
    Select
} from 'antd';
import {
    ClockCircleOutlined,
    UserOutlined,
    MailOutlined,
    PhoneOutlined,
    EnvironmentOutlined,
    LeftOutlined,
    RightOutlined,
    CalendarOutlined
} from '@ant-design/icons';
import dayjs from 'dayjs';
import adminService from '../../services/adminService';

const { Title, Text } = Typography;

const Interviews = () => {
    const [interviews, setInterviews] = useState([]);
    const [selectedDate, setSelectedDate] = useState(dayjs());
    const [loading, setLoading] = useState(false);
    const [viewModalVisible, setViewModalVisible] = useState(false);
    const [selectedInterview, setSelectedInterview] = useState(null);
    const [calendarMode, setCalendarMode] = useState('month');

    // Fetch interviews for the selected month
    const fetchInterviews = async (date) => {
        try {
            setLoading(true);
            const startDate = date.startOf('month').toISOString();
            const endDate = date.endOf('month').toISOString();

            const response = await adminService.getInterviews(startDate, endDate);
            if (response.status === 'success') {
                setInterviews(response.data);
            } else {
                console.error('Failed to fetch interviews:', response.message);
            }
        } catch (error) {
            console.error('Error fetching interviews:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchInterviews(selectedDate);
    }, [selectedDate]);

    // Get interviews for a specific date
    const getInterviewsForDate = (date) => {
        return interviews.filter(interview =>
            dayjs(interview.interview_time).isSame(date, 'day')
        );
    };

    // Enhanced calendar cell render with better styling
    const dateCellRender = (date) => {
        const dayInterviews = getInterviewsForDate(date);

        if (dayInterviews.length === 0) {
            return null;
        }

        return (
            <div className="events" style={{ maxHeight: '100%', overflow: 'hidden' }}>
                {dayInterviews.slice(0, 2).map((interview, index) => (
                    <Tooltip
                        key={index}
                        title={`${interview.user_name} - ${interview.position}`}
                        placement="top"
                    >
                        <div style={{
                            marginBottom: '4px',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            padding: '1px 4px',
                            borderRadius: '4px',
                            backgroundColor: '#e6f7ff',
                            borderLeft: '3px solid #1890ff',
                            fontSize: '12px'
                        }}>
                            <Badge
                                color="#1890ff"
                                text={`${dayjs(interview.interview_time).format('HH:mm')} - ${interview.user_name}`}
                                style={{ fontSize: '12px' }}
                            />
                        </div>
                    </Tooltip>
                ))}
                {dayInterviews.length > 2 && (
                    <Tag color="blue" style={{ fontSize: '11px', marginTop: '2px' }}>
                        +{dayInterviews.length - 2} more
                    </Tag>
                )}
            </div>
        );
    };

    // Calendar header style
    const calendarHeaderStyle = {
        paddingLeft: '12px',
        paddingRight: '12px',
        borderBottom: '1px solid #f0f0f0'
    };

    // Month cell content render for better month view
    const monthCellRender = (date) => {
        const monthInterviews = interviews.filter(interview =>
            dayjs(interview.interview_time).isSame(date, 'month')
        );

        if (monthInterviews.length === 0) {
            return null;
        }

        return (
            <div style={{ padding: '8px 0', textAlign: 'center' }}>
                <Tag color="blue">{monthInterviews.length} interviews</Tag>
            </div>
        );
    };

    // Handle calendar date selection
    const onSelect = (date) => {
        setSelectedDate(date);
    };

    // Handle calendar date change (month/year)
    const onPanelChange = (date) => {
        setSelectedDate(date);
        fetchInterviews(date);
    };

    // Navigate to previous month
    const navigateToPreviousMonth = () => {
        const newDate = selectedDate.subtract(1, 'month');
        setSelectedDate(newDate);
        fetchInterviews(newDate);
    };

    // Navigate to next month
    const navigateToNextMonth = () => {
        const newDate = selectedDate.add(1, 'month');
        setSelectedDate(newDate);
        fetchInterviews(newDate);
    };

    // Navigate to current month
    const navigateToCurrentMonth = () => {
        const currentDate = dayjs();
        setSelectedDate(currentDate);
        fetchInterviews(currentDate);
    };

    // Handle calendar mode change (month/year)
    const handleCalendarModeChange = (e) => {
        setCalendarMode(e.target.value);
    };

    return (
        <div style={{ padding: '25px', maxWidth: '100%', margin: '0 auto', overflow: 'hidden' }}>
            <Row gutter={[24, 24]}>
                {/* Calendar Section */}
                <Col xs={24} lg={16}>
                    <Card
                        title={
                            <div style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center'
                            }}>
                                <span>Interview Calendar</span>
                                <Tag color="processing">
                                    {interviews.length} Total Interviews
                                </Tag>
                            </div>
                        }
                        bordered={false}
                        style={{
                            boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
                            borderRadius: '8px'
                        }}
                        extra={
                            <Space>
                                <Radio.Group
                                    value={calendarMode}
                                    onChange={handleCalendarModeChange}
                                    buttonStyle="solid"
                                    size="small"
                                >
                                    <Radio.Button value="month">Month</Radio.Button>
                                    <Radio.Button value="year">Year</Radio.Button>
                                </Radio.Group>
                            </Space>
                        }
                    >
                        <div style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            marginBottom: '16px',
                            padding: '0 8px'
                        }}>
                            <Button
                                icon={<LeftOutlined />}
                                onClick={navigateToPreviousMonth}
                                type="primary"
                                ghost
                            />

                            <div style={{
                                fontWeight: 'bold',
                                fontSize: '16px',
                                display: 'flex',
                                alignItems: 'center',
                                gap: '8px'
                            }}>
                                {selectedDate.format('MMMM YYYY')}
                                <Button
                                    icon={<CalendarOutlined />}
                                    size="small"
                                    onClick={navigateToCurrentMonth}
                                    title="Go to current month"
                                    type="link"
                                >
                                    Today
                                </Button>
                            </div>

                            <Button
                                icon={<RightOutlined />}
                                onClick={navigateToNextMonth}
                                type="primary"
                                ghost
                            />
                        </div>

                        <Spin spinning={loading}>
                            <div className="custom-calendar">
                                <Calendar
                                    dateCellRender={dateCellRender}
                                    monthCellRender={monthCellRender}
                                    onSelect={onSelect}
                                    onPanelChange={onPanelChange}
                                    value={selectedDate}
                                    mode={calendarMode}
                                    fullscreen={true}
                                    headerRender={() => null} // Hide default header since we have our custom navigation
                                />
                            </div>
                        </Spin>
                    </Card>
                </Col>

                {/* Daily Schedule Section */}
                <Col xs={24} lg={8}>
                    <Card
                        title={
                            <div style={{ fontSize: '16px', fontWeight: 'bold', color: '#1890ff' }}>
                                Interviews for {selectedDate.format('MMMM D, YYYY')}
                            </div>
                        }
                        bordered={false}
                        style={{
                            boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
                            borderRadius: '8px',
                            height: '100%'
                        }}
                        bodyStyle={{
                            padding: '0 12px',
                            maxHeight: '400px',
                            overflow: 'auto'
                        }}
                    >
                        <List
                            itemLayout="vertical"
                            dataSource={getInterviewsForDate(selectedDate)}
                            locale={{ emptyText: <Empty description="No interviews scheduled" /> }}
                            renderItem={interview => (
                                <List.Item
                                    style={{
                                        borderBottom: '1px solid #f0f0f0',
                                        paddingBottom: '12px',
                                        paddingTop: '12px',
                                        marginBottom: '0'
                                    }}
                                    actions={[
                                        <Button
                                            type="primary"
                                            size="small"
                                            onClick={() => {
                                                setSelectedInterview(interview);
                                                setViewModalVisible(true);
                                            }}
                                        >
                                            View Details
                                        </Button>
                                    ]}
                                >
                                    <List.Item.Meta
                                        title={
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <Text strong style={{ fontSize: '16px' }}>
                                                    {interview.user_name}
                                                </Text>
                                                <Tag color="blue" style={{ marginLeft: '8px' }}>
                                                    {dayjs(interview.interview_time).format('HH:mm')}
                                                </Tag>
                                            </div>
                                        }
                                        description={
                                            <Space direction="vertical" size={4}>
                                                <div>
                                                    <EnvironmentOutlined style={{ color: '#1890ff' }} />
                                                    <Text type="secondary" style={{ marginLeft: '5px' }}>
                                                        {interview.position}
                                                    </Text>
                                                </div>
                                            </Space>
                                        }
                                    />
                                </List.Item>
                            )}
                        />
                    </Card>
                </Col>
            </Row>

            {/* Interview Details Modal */}
            <Modal
                title="Interview Details"
                open={viewModalVisible}
                onCancel={() => setViewModalVisible(false)}
                footer={[
                    <Button key="close" onClick={() => setViewModalVisible(false)}>
                        Close
                    </Button>
                ]}
                bodyStyle={{ padding: '16px 24px' }}
            >
                {selectedInterview && (
                    <Space direction="vertical" size="large" style={{ width: '100%' }}>
                        <Card style={{ boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)' }}>
                            <Space direction="vertical" size={4}>
                                <Title level={4}>{selectedInterview.user_name}</Title>
                                <Text>
                                    <UserOutlined /> Position: {selectedInterview.position}
                                </Text>
                                <Text>
                                    <MailOutlined /> {selectedInterview.user_email}
                                </Text>
                                <Text>
                                    <PhoneOutlined /> {selectedInterview.user_phone}
                                </Text>
                                <Text>
                                    <ClockCircleOutlined /> {dayjs(selectedInterview.interview_time).format('MMMM D, YYYY HH:mm')}
                                </Text>
                            </Space>
                        </Card>

                        {selectedInterview.notes && (
                            <Card
                                title="Interview Notes"
                                style={{ boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)' }}
                            >
                                <Text>{selectedInterview.notes}</Text>
                            </Card>
                        )}
                    </Space>
                )}
            </Modal>
        </div>
    );
};

export default Interviews;
