import React, { useState, useEffect } from 'react';
import {
    Card,
    Form,
    Input,
    Button,
    message,
    Tabs,
    Table,
    Space,
    Modal,
    Select,
    Row,
    Col,
    Avatar,
    Typography
} from 'antd';
import {
    UserOutlined,
    LockOutlined,
    MailOutlined,
    TeamOutlined,
    EditOutlined,
    KeyOutlined
} from '@ant-design/icons';
import adminService from '../../services/adminService';

const { TabPane } = Tabs;
const { Option } = Select;
const { Title, Text } = Typography;

// Constants
const PRIMARY_COLOR = '#1890ff';
const SECONDARY_COLOR = '#001529';

const Profile = () => {
    const [loading, setLoading] = useState(false);
    const [profile, setProfile] = useState(null);
    const [admins, setAdmins] = useState([]);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [form] = Form.useForm();
    const [createForm] = Form.useForm();

    // Fetch profile data
    const fetchProfile = async () => {
        try {
            const data = await adminService.getProfile();
            setProfile(data);
            form.setFieldsValue(data);
        } catch (error) {
            message.error('Failed to fetch profile');
        }
    };

    // Fetch admins list
    const fetchAdmins = async () => {
        try {
            const data = await adminService.listAdmins();
            setAdmins(data);
        } catch (error) {
            message.error('Failed to fetch admins');
        }
    };

    useEffect(() => {
        fetchProfile();
        fetchAdmins();
    }, []);

    // Handle profile update
    const handleProfileUpdate = async (values) => {
        try {
            setLoading(true);
            await adminService.updateProfile(values);
            message.success('Profile updated successfully');
            fetchProfile();
        } catch (error) {
            message.error(error.message);
        } finally {
            setLoading(false);
        }
    };

    // Handle admin creation
    const handleCreateAdmin = async (values) => {
        try {
            setLoading(true);
            await adminService.createAdmin(values);
            message.success('Admin created successfully');
            fetchAdmins();
            setIsModalVisible(false);
            createForm.resetFields();
        } catch (error) {
            message.error(error.message);
        } finally {
            setLoading(false);
        }
    };

    const columns = [
        {
            title: 'Username',
            dataIndex: 'username',
            key: 'username',
            render: (text) => (
                <Space>
                    <Avatar size="small" icon={<UserOutlined />} />
                    {text}
                </Space>
            ),
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'Role',
            dataIndex: 'role',
            key: 'role',
            render: (text) => (
                <Text style={{ 
                    color: text === 'super_admin' ? '#1890ff' : '#52c41a',
                    textTransform: 'capitalize'
                }}>
                    {text.replace('_', ' ')}
                </Text>
            ),
        },
        {
            title: 'Created At',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (text) => text ? new Date(text).toLocaleDateString() : 'N/A',
        }
    ];

    return (
        <div style={{ padding: '24px', maxWidth: '1200px', margin: '0 auto' }}>
            <Card 
                bordered={false}
                style={{ 
                    borderRadius: '8px',
                    boxShadow: '0 2px 8px rgba(0,0,0,0.1)'
                }}
            >
                <Tabs defaultActiveKey="1" style={{ marginTop: '-12px' }}>
                    <TabPane
                        tab={
                            <span>
                                <UserOutlined />
                                Profile Settings
                            </span>
                        }
                        key="1"
                    >
                        <Row gutter={[24, 24]} align="top">
                            <Col xs={24}>
                                <Form
                                    form={form}
                                    layout="vertical"
                                    onFinish={handleProfileUpdate}
                                    initialValues={profile}
                                >
                                    <Title level={4}>
                                        <EditOutlined /> Personal Information
                                    </Title>
                                    <Row gutter={16}>
                                        <Col xs={24} sm={12}>
                                            <Form.Item
                                                name="username"
                                                label="Username"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please input your username!',
                                                    },
                                                ]}
                                            >
                                                <Input 
                                                    prefix={<UserOutlined />} 
                                                    placeholder="Username"
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={12}>
                                            <Form.Item
                                                name="email"
                                                label="Email"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please input your email!',
                                                    },
                                                    {
                                                        type: 'email',
                                                        message: 'Please enter a valid email!',
                                                    },
                                                ]}
                                            >
                                                <Input 
                                                    prefix={<MailOutlined />} 
                                                    placeholder="Email"
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>

                                    <Title level={4} style={{ marginTop: '24px' }}>
                                        <KeyOutlined /> Change Password
                                    </Title>
                                    <Row gutter={16}>
                                        <Col xs={24} sm={12}>
                                            <Form.Item
                                                name="currentPassword"
                                                label="Current Password"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please input your current password!',
                                                    },
                                                ]}
                                            >
                                                <Input.Password 
                                                    prefix={<LockOutlined />} 
                                                    placeholder="Current Password"
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={12}>
                                            <Form.Item
                                                name="newPassword"
                                                label="New Password"
                                            >
                                                <Input.Password 
                                                    prefix={<LockOutlined />} 
                                                    placeholder="Leave blank to keep current password"
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>

                                    <Form.Item style={{ marginTop: '16px' }}>
                                        <Button 
                                            type="primary" 
                                            htmlType="submit" 
                                            loading={loading}
                                            icon={<EditOutlined />}
                                        >
                                            Update Profile
                                        </Button>
                                    </Form.Item>
                                </Form>
                            </Col>
                        </Row>
                    </TabPane>
                    {/* Commenting out Manage Admins Panel
                    <TabPane
                        tab={
                            <span>
                                <TeamOutlined />
                                Manage Admins
                            </span>
                        }
                        key="2"
                    >
                        <div style={{ marginBottom: '16px' }}>
                            <Button
                                type="primary"
                                onClick={() => setIsModalVisible(true)}
                                icon={<UserOutlined />}
                            >
                                Add New Admin
                            </Button>
                        </div>
                        <Table
                            columns={columns}
                            dataSource={admins}
                            rowKey="id"
                            loading={loading}
                        />
                        <Modal
                            title="Create New Admin"
                            visible={isModalVisible}
                            onCancel={() => setIsModalVisible(false)}
                            footer={null}
                        >
                            <Form
                                form={createForm}
                                layout="vertical"
                                onFinish={handleCreateAdmin}
                            >
                                <Form.Item
                                    name="username"
                                    label="Username"
                                    rules={[{ required: true }]}
                                >
                                    <Input prefix={<UserOutlined />} />
                                </Form.Item>
                                <Form.Item
                                    name="email"
                                    label="Email"
                                    rules={[
                                        { required: true },
                                        { type: 'email' }
                                    ]}
                                >
                                    <Input prefix={<MailOutlined />} />
                                </Form.Item>
                                <Form.Item
                                    name="password"
                                    label="Password"
                                    rules={[{ required: true }]}
                                >
                                    <Input.Password prefix={<LockOutlined />} />
                                </Form.Item>
                                <Form.Item
                                    name="role"
                                    label="Role"
                                    rules={[{ required: true }]}
                                >
                                    <Select>
                                        <Option value="admin">Admin</Option>
                                        <Option value="super_admin">Super Admin</Option>
                                    </Select>
                                </Form.Item>
                                <Form.Item>
                                    <Button type="primary" htmlType="submit" loading={loading} block>
                                        Create Admin
                                    </Button>
                                </Form.Item>
                            </Form>
                        </Modal>
                    </TabPane>
                    */}
                </Tabs>
            </Card>
        </div>
    );
};

export default Profile;
