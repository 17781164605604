import { useState, React } from 'react';
import { Typography, Space, Spin } from 'antd';
import { CreditCardOutlined } from '@ant-design/icons';

import Lottie from 'lottie-react';
import successAnimation from '../animations/paymentSuccess.json';
import failureAnimation from '../animations/paymentFailed.json';
import { apiBaseUrl, webSocketUrl } from "../../constants/apiDetails";
import sendPaymentConfirmationEmail from '../../services/userService';
import PayPalOpt from './PayPalOption/PayPalOpt';
// import JazzCashPayment from './JazzCash/jazzcash';

const { Title } = Typography;


const PaymentOptions = ({ userID }) => {
    const [processingPayment, setProcessingPayment] = useState(false);
    const [paymentStatus, setPaymentStatus] = useState(null);
    const [transactionRefrence, setTransactionRefrence] = useState(null);

    // == Payment Variables ==
    const currency = "SAR";
    const amount = 15.00;
    const apiUrl = apiBaseUrl + '/createPayment';



    const handlePaymentClick = () => {
        setProcessingPayment(true);

        // Generating Payment URL
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ amount: amount, currency: currency, user_id: userID }),
        };

        fetch(apiUrl, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                const redirectUrl = data.payment_url;
                window.open(redirectUrl, '_blank');

                const transactionRef = data.tran_ref;
                setTransactionRefrence(transactionRef);

                // Listening for payment status callback
                const ws = new WebSocket(`${webSocketUrl}/payment-status/${transactionRef}`);

                // Handle WebSocket connection open
                ws.onmessage = (event) => {
                    console.log("Message received from server:", event.data);
                    const message = JSON.parse(event.data);

                    if (message.status === 'success') {
                        setPaymentStatus('success');
                        setProcessingPayment(false);
                        ws.close(); // Close the WebSocket after success
                        
                        // Sending Payment Confirmation Email to user
                        const user_id = message.user_id;
                        sendPaymentConfirmationEmail(user_id);

                    } else if (message.status === 'failure') {
                        setPaymentStatus('failure');
                        setProcessingPayment(false);
                        ws.close(); // Close the WebSocket after failure
                    }
                };

                // Handle WebSocket connection open
                ws.onopen = () => {
                    console.log('WebSocket connection opened');
                };

                // Handle WebSocket connection close
                ws.onclose = () => {
                    console.log('WebSocket connection closed');
                };

                // Handle WebSocket connection error
                ws.onerror = (error) => {
                    console.error('WebSocket error:', error);
                };


            })
            .catch((error) => {
                console.error('Error:', error);
                setPaymentStatus('failure');
                setProcessingPayment(false);
            });
    };


    // If payment is successfull, show the success message
    if (paymentStatus === 'success') {
        return (
            <div style={{ 
                textAlign: 'center', 
                padding: '15px 10px', 
                border: '1px solid #f0f0f0', 
                borderRadius: '8px', 
                boxShadow: '0px 4px 6px rgba(0,0,0,0.1)', 
                width: '100%', 
                maxWidth: '500px', 
                margin: '0 auto' 
            }}>
                <Title level={5} style={{ marginBottom: '15px' }}>
                    <Lottie animationData={successAnimation} style={{ height: 150, margin: '0 auto' }} />

                    <CreditCardOutlined style={{ marginRight: '8px', color: '#1890ff' }} />
                    Payment Successful
                </Title>
                <p style={{ color: 'green', fontWeight: 'bold' }}>Your payment has been successfully processed. A confirmation email will be sent to you shortly.<br />
                    Your transaction reference number is: <strong>{transactionRefrence}</strong>. <br />
                    You may now safely close this window.</p>
            </div>
        );
    }

    // If payment is failed, show the failure message
    if (paymentStatus === 'failure') {
        return (
            <div style={{ 
                textAlign: 'center', 
                padding: '15px 10px', 
                border: '1px solid #f0f0f0', 
                borderRadius: '8px', 
                boxShadow: '0px 4px 6px rgba(0,0,0,0.1)', 
                width: '100%', 
                maxWidth: '500px', 
                margin: '0 auto' 
            }}>
                <Title level={5} style={{ marginBottom: '15px' }}>
                    <Lottie animationData={failureAnimation} style={{ height: 150, margin: '0 auto' }} />
                    <CreditCardOutlined style={{ marginRight: '8px', color: '#1890ff' }} />
                    Payment Failed
                </Title>
                <p style={{ color: 'red', fontWeight: 'bold' }}>Your payment has failed. Please try again later.</p>
            </div>
        );
    }


    return (
        <div style={{ 
            textAlign: 'center', 
            padding: '15px 10px', 
            border: '1px solid #f0f0f0', 
            borderRadius: '8px', 
            boxShadow: '0px 4px 6px rgba(0,0,0,0.1)', 
            width: '100%', 
            maxWidth: '500px', 
            margin: '0 auto' 
        }}>
            <Title level={5} style={{ marginBottom: '15px' }}>
                <CreditCardOutlined style={{ marginRight: '8px', color: '#1890ff' }} />
                Choose Payment Method
            </Title>
            {/* If the payment is processing, show the spinner */}
            {processingPayment ? (
                <Spin size="large" />
            ) : (
                <Space direction="vertical" size="middle" style={{ width: '100%' }}>
                    {/* <Button type="primary" block icon={<CreditCardOutlined />} style={{ fontWeight: 'bold' }} onClick={handlePaymentClick}>
                        Pay with Card
                    </Button> */}
                    <PayPalOpt 
                        paymentAmount={"4.00"}
                        userID={userID}
                    />
                </Space>
            )}
        </div>
    );
};


export default PaymentOptions;