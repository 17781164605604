import React, { useState, useEffect, useCallback } from 'react';
import {
    Table,
    Input,
    Select,
    Card,
    Space,
    Tag,
    Tooltip,
    Button,
    Modal,
    Form,
    message,
    Typography,
    Row,
    Col,
    Divider,
    DatePicker,
    TimePicker,
    notification
} from 'antd';
import {
    SearchOutlined,
    EditOutlined,
    DeleteOutlined,
    EyeOutlined,
    ClearOutlined,
    FilterOutlined
} from '@ant-design/icons';
import AuthLoader from './AuthLoader';
import { apiBaseUrl } from '../../constants/apiDetails';
import { useNavigate } from 'react-router-dom';
import userService from '../../services/userService';
import { getNationalityOptions } from '../../constants/nationalities';
import { getPositionOptions, getAllPositions } from '../../constants/positions';
import PhoneNumberInput from '../FormCoponents/PhoneNumberInput';
import debounce from 'lodash.debounce';
import dayjs from 'dayjs';

import { CheckCircleOutlined, SyncOutlined, CloseCircleOutlined, LoadingOutlined } from '@ant-design/icons';

const { Option } = Select;
const { Title, Text } = Typography;

const UserManagementDashboard = () => {
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const [editModalVisible, setEditModalVisible] = useState(false);
    const [viewModalVisible, setViewModalVisible] = useState(false);
    const [scheduleModalVisible, setScheduleModalVisible] = useState(false);
    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedTime, setSelectedTime] = useState(null);
    const [interviewNotes, setInterviewNotes] = useState('');
    const [filters, setFilters] = useState({
        search: '',
        role: '',
        status: '',
        nationality: '',
        position: ''
    });
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10,
        total: 0
    });
    const [isAuthenticated, setIsAuthenticated] = useState(false); // Ensure proper authentication state
    const [authLoading, setAuthLoading] = useState(true); // Separate loader for authentication
    const [form] = Form.useForm();

    const navigate = useNavigate();

    // Debounce search input
    const debouncedSearch = useCallback(
        debounce((value) => {
            setFilters(prev => ({ ...prev, search: value }));
            setPagination(prev => ({ ...prev, current: 1 }));
        }, 500),
        []
    );

    // Handle table pagination change
    const handleTableChange = async (newPagination, tableFilters, sorter) => {
        console.log('Table change:', {
            newPagination,
            currentPagination: pagination,
            tableFilters,
            sorter
        }); // Debug log

        // Update pagination state immediately
        const updatedPagination = {
            ...pagination,
            current: newPagination.current,
            pageSize: Number(newPagination.pageSize)
        };

        console.log('Updated pagination:', updatedPagination); // Debug log
        setPagination(updatedPagination);

        // Fetch data with new pagination
        try {
            setLoading(true);
            const result = await userService.getUsers(filters, updatedPagination);
            setUsers(result.data);
            setPagination(prev => ({
                ...prev,
                total: result.total
            }));
        } catch (error) {
            message.error('Failed to fetch users');
            console.error('Error:', error);
        } finally {
            setLoading(false);
        }
    };

    // Fetch users with filters
    const fetchUsers = useCallback(async () => {
        try {
            setLoading(true);

            // Fetch data from the backend
            const result = await userService.getUsers(filters, pagination);

            // Update state with the fetched data
            setUsers(result.data);
            setPagination(prev => ({
                ...prev,
                total: result.total,
                current: result.currentPage
            }));
        } catch (error) {
            message.error('Failed to fetch users');
            console.error('Error:', error);
        } finally {
            setLoading(false);
        }
    }, [filters, pagination.current, pagination.pageSize]);

    // Handle filter changes
    const handleFilterChange = (type, value) => {
        setFilters(prev => ({
            ...prev,
            [type]: value || '' // Convert null/undefined to an empty string
        }));
        setPagination(prev => ({ ...prev, current: 1 })); // Reset to the first page

        // Fetch updated data
        fetchUsers();
    };

    // Reset filters
    const handleResetFilters = () => {
        setFilters({
            search: '',
            role: '',
            status: '',
            nationality: '',
            position: ''
        });
        setPagination(prev => ({ ...prev, current: 1 }));
        fetchUsers(); // Immediate fetch after state updates
    };

    // Fetching all users Data from API, passing token in headers
    const fetchUsersData = async () => {
        setLoading(true);
        try {
            const response = await userService.getUsers(filters);
            // Sorting users by date of registration in descending order (latest first)
            const sortedUsers = response.data.sort((a, b) =>
                new Date(b.date_of_registration) - new Date(a.date_of_registration)
            );
            setUsers(sortedUsers);
            setPagination(prev => ({
                ...prev,
                total: response.total
            }));
        } catch (error) {
            message.error('Failed to fetch users');
            if (error.message === 'No authentication token found') {
                navigate('/admin');
            }
        } finally {
            setLoading(false);
        }
    };

    // Verify token on mount
    useEffect(() => {
        const token = localStorage.getItem('token');
        if (!token) {
            navigate('/admin');
            return;
        }

        const verifyToken = async () => {
            try {
                const response = await fetch(`${apiBaseUrl}/verify-token`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `${token}`,
                    }
                });

                const data = await response.json();
                if (data.status === "success") {
                    setIsAuthenticated(true);
                } else {
                    localStorage.removeItem('token');
                    navigate('/admin');
                }
            } catch (error) {
                console.error('Token verification failed:', error);
                localStorage.removeItem('token');
                navigate('/admin');
            } finally {
                setAuthLoading(false); // Stop the authentication loader
            }
        };

        verifyToken();
    }, [navigate]);

    // Fetch users when authenticated
    useEffect(() => {
        if (isAuthenticated) {
            handleTableChange(pagination, filters);
        }
    }, [isAuthenticated]);

    if (authLoading) {
        return <AuthLoader />; // Show loader only during authentication
    }

    if (!isAuthenticated) {
        return null; // Prevent rendering if not authenticated
    }

    // Edit user handler
    const handleEditUser = async (values) => {
        try {
            // Format the values before sending to API
            const formattedValues = {
                ...values,
                phoneNumber: values.phoneNumber ? `${values.phoneNumber}` : values.phoneNumber
            };

            await userService.updateUser(selectedUser._id, formattedValues);
            message.success('User updated successfully');
            setEditModalVisible(false);
            fetchUsers();
        } catch (error) {
            message.error('Failed to update user');
        }
    };

    // Delete user handler
    const handleDeleteUser = async (userId) => {
        Modal.confirm({
            title: 'Are you sure you want to delete this user?',
            content: 'This action cannot be undone',
            onOk: async () => {
                try {
                    await userService.deleteUser(userId);
                    message.success('User deleted successfully');
                    fetchUsers();
                } catch (error) {
                    message.error('Failed to delete user');
                }
            }
        });
    };

    // Handle application status update
    const handleStatusUpdate = async (userId, status) => {
        try {
            const response = await userService.updateApplicationStatus(userId, status);
            if (response.status === 'success') {
                message.success(`Application ${status} successfully`);
                fetchUsers();
            } else {
                message.error('Failed to update application status');
            }
        } catch (error) {
            message.error('Failed to update application status');
        }
    };

    // Handle interview scheduling
    const handleScheduleInterview = async () => {
        if (!selectedDate || !selectedTime) {
            message.error('Please select both date and time');
            return;
        }

        const interviewTime = dayjs(selectedDate)
            .hour(selectedTime.hour())
            .minute(selectedTime.minute())
            .format();

        try {
            const response = await userService.scheduleInterview({
                userId: selectedUser._id,
                interviewTime,
                adminId: localStorage.getItem('adminId'),
                notes: interviewNotes
            });

            if (response.status === 'success') {
                message.success('Interview scheduled successfully');
                setScheduleModalVisible(false);
                fetchUsers(); // Refresh user list
            } else {
                message.error('Failed to schedule interview');
            }
        } catch (error) {
            message.error('Failed to schedule interview');
        }
    };

    // Table columns definition
    const columns = [
        {
            title: 'Full Name',
            dataIndex: 'fullName',
            key: 'fullName',
            sorter: (a, b) => a.fullName.localeCompare(b.fullName)
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email'
        },
        {
            title: 'Position',
            dataIndex: 'positionApplying',
            key: 'positionApplying',
            filters: getAllPositions().map(position => ({
                text: position,
                value: position
            })),
            onFilter: (value, record) => record.positionApplying === value,
        },
        {
            title: 'Payment Status',
            dataIndex: 'payment_status',
            key: 'payment_status',
            render: (status) => (


                <Tag color={
                    status === 'success' ? 'success' :
                        status === 'pending' ? 'processing' : 'error'
                }

                    icon={
                        status === 'success' ? <CheckCircleOutlined /> :
                            status === 'pending' ? <SyncOutlined spin /> : <CloseCircleOutlined />
                    }

                >
                    {status.toUpperCase()}
                </Tag>
            )
        },
        {
            title: 'Nationality',
            dataIndex: 'nationality',
            key: 'nationality',
            filters: getNationalityOptions().map(option => ({
                text: option.label,
                value: option.value
            })),
            onFilter: (value, record) => record.nationality === value,
        },
        {
            title: 'Phone',
            dataIndex: 'phoneNumber',
            key: 'phoneNumber',
        },
        {
            title: 'Registration Date',
            dataIndex: 'date_of_registration',
            key: 'date_of_registration',
            render: (date) => {
                const d = new Date(date);
                return d.toLocaleDateString('en-GB', {
                    day: '2-digit',
                    month: 'short',
                    year: 'numeric'
                });
            },
            sorter: (a, b) => new Date(a.date_of_registration) - new Date(b.date_of_registration)
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (_, record) => (
                <Space>
                    <Tooltip title="View Details">
                        <Button
                            icon={<EyeOutlined />}
                            size="small"
                            type="primary"
                            ghost
                            onClick={() => {
                                setSelectedUser(record);
                                setViewModalVisible(true);
                            }}
                        />
                    </Tooltip>
                    <Tooltip title="Edit User">
                        <Button
                            icon={<EditOutlined />}
                            size="small"
                            type="default"
                            onClick={() => {
                                console.log(record);
                                setSelectedUser(record);
                                form.setFieldsValue(record);
                                setEditModalVisible(true);
                            }}
                        />
                    </Tooltip>
                    <Tooltip title="Delete User">
                        <Button
                            icon={<DeleteOutlined />}
                            size="small"
                            type="danger"
                            onClick={() => handleDeleteUser(record._id)}
                        />
                    </Tooltip>
                </Space>
            )
        },
        {
            title: 'Application Status',
            key: 'application_status',
            render: (_, record) => (
                <Space>
                    <Button
                        type={record.application_status === 'scheduled' ? 'primary' : 'default'}
                        onClick={() => {
                            setSelectedUser(record);
                            setScheduleModalVisible(true);
                        }}

                    >
                        {record.application_status === 'scheduled' ? 'Interview Scheduled' : 'Schedule Interview'}


                    </Button>
                    <Button
                        danger
                        type={record.application_status === 'rejected' ? 'primary' : 'default'}
                        onClick={() => handleStatusUpdate(record._id, 'rejected')}
                    >
                        Reject
                    </Button>
                </Space>
            )
        }
    ];

    return (
        <div className="user-management-dashboard">
            <Card
                title={<Title level={2} style={{ margin: 0, textAlign: 'center', fontWeight: 'bold' }}>User Management</Title>}
                style={{ margin: '16px' }}
                className="user-management-card"
            >
                {/* Filters Section */}
                <div style={{ marginBottom: '24px' }}>
                    <div style={{ marginBottom: '16px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Space>
                            <FilterOutlined style={{ color: '#8c8c8c' }} />
                            <Text strong>Filters</Text>
                        </Space>
                        <Button
                            onClick={handleResetFilters}
                            icon={<ClearOutlined />}
                            size="small"
                            type="link"
                        >
                            Clear all
                        </Button>
                    </div>
                    <Row gutter={[16, 16]} align="middle">
                        {/* Search Input */}
                        <Col xs={24} sm={12} md={6} lg={6}>
                            <Form.Item
                                label="Search"
                                style={{ marginBottom: '8px' }}
                            >
                                <Input
                                    prefix={<SearchOutlined style={{ color: '#bfbfbf' }} />}
                                    placeholder="Name, email, or passport"
                                    onChange={(e) => debouncedSearch(e.target.value)}
                                    allowClear
                                    size="middle"
                                />
                            </Form.Item>
                        </Col>

                        <Col xs={24} sm={24} md={18} lg={18}>
                            <Row gutter={[16, 16]}>
                                {/* Position Filter */}
                                <Col xs={24} sm={8} md={8} lg={8}>
                                    <Form.Item
                                        label="Position"
                                        style={{ marginBottom: '8px' }}
                                    >
                                        <Select
                                            placeholder="Select position"
                                            allowClear
                                            style={{ width: '100%' }}
                                            onChange={(value) => handleFilterChange('position', value)}
                                            value={filters.position}
                                            size="middle"
                                            showSearch
                                            optionFilterProp="children"
                                        >
                                            {getPositionOptions().map(category => (
                                                <Select.OptGroup key={category.label} label={category.label}>
                                                    {category.options.map(option => (
                                                        <Option key={option.value} value={option.value}>
                                                            {option.label}
                                                        </Option>
                                                    ))}
                                                </Select.OptGroup>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>

                                {/* Status Filter */}
                                <Col xs={12} sm={8} md={8} lg={8}>
                                    <Form.Item
                                        label="Status"
                                        style={{ marginBottom: '8px' }}
                                    >
                                        <Select
                                            placeholder="Select status"
                                            allowClear
                                            style={{ width: '100%' }}
                                            onChange={(value) => handleFilterChange('status', value)}
                                            value={filters.status}
                                            size="middle"
                                        >
                                            <Option value="pending">Pending</Option>
                                            <Option value="approved">Approved</Option>
                                            <Option value="rejected">Rejected</Option>
                                        </Select>
                                    </Form.Item>
                                </Col>

                                {/* Nationality Filter */}
                                <Col xs={12} sm={8} md={8} lg={8}>
                                    <Form.Item
                                        label="Nationality"
                                        style={{ marginBottom: '8px' }}
                                    >
                                        <Select
                                            placeholder="Select nationality"
                                            allowClear
                                            style={{ width: '100%' }}
                                            onChange={(value) => handleFilterChange('nationality', value)}
                                            value={filters.nationality}
                                            showSearch
                                            optionFilterProp="children"
                                            size="middle"
                                        >
                                            {getNationalityOptions().map(option => (
                                                <Option key={option.value} value={option.value}>
                                                    {option.label}
                                                </Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>

                {/* Active Filters */}
                {Object.values(filters).some(value => value !== '') && (
                    <>
                        <Divider style={{ margin: '0 0 16px 0' }} />
                        <div style={{ marginBottom: '16px' }}>
                            <Space size={[0, 8]} wrap>
                                <Text type="secondary" style={{ marginRight: '8px' }}>Active filters:</Text>
                                {filters.search && (
                                    <Tag closable onClose={() => handleFilterChange('search', '')} style={{ marginRight: '8px' }}>
                                        Search: {filters.search}
                                    </Tag>
                                )}
                                {filters.position && (
                                    <Tag closable onClose={() => handleFilterChange('position', '')} style={{ marginRight: '8px' }}>
                                        Position: {filters.position}
                                    </Tag>
                                )}
                                {filters.status && (
                                    <Tag closable onClose={() => handleFilterChange('status', '')} style={{ marginRight: '8px' }}>
                                        Status: {filters.status}
                                    </Tag>
                                )}
                                {filters.nationality && (
                                    <Tag closable onClose={() => handleFilterChange('nationality', '')} style={{ marginRight: '8px' }}>
                                        Nationality: {filters.nationality}
                                    </Tag>
                                )}
                            </Space>
                        </div>
                    </>
                )}

                {/* Table Section */}
                <Table
                    columns={columns}
                    dataSource={users}
                    rowKey="_id"
                    pagination={{
                        current: pagination.current,
                        pageSize: Number(pagination.pageSize),
                        total: pagination.total,
                        showSizeChanger: true,
                        showQuickJumper: true,
                        showTotal: (total) => `Total ${total} users`,
                        pageSizeOptions: ['10', '20', '50', '100'],
                        size: "small",
                        responsive: true,
                        position: ['bottomCenter']
                    }}
                    onChange={handleTableChange}
                    loading={loading}
                    scroll={{ x: 'max-content' }}
                    size="small"
                />

                {/* View User Modal */}
                <Modal
                    title="User Details"
                    open={viewModalVisible}
                    onCancel={() => setViewModalVisible(false)}
                    footer={[
                        <Button key="close" onClick={() => setViewModalVisible(false)}>
                            Close
                        </Button>
                    ]}
                    width={800}
                >
                    {selectedUser && (
                        <div style={{ padding: '20px' }}>
                            {/* Personal Information */}
                            <Card bordered style={{ marginBottom: '20px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
                                <Title level={4}>Personal Information</Title>
                                <Divider />
                                <Row gutter={[16, 16]}>
                                    <Col span={12}>
                                        <Text><strong>Full Name:</strong> {selectedUser.fullName}</Text><br />
                                        <Text><strong>Passport Number:</strong> {selectedUser.passportNumber}</Text><br />
                                        <Text><strong>Nationality:</strong> {selectedUser.nationality}</Text><br />
                                        <Text><strong>Address:</strong> {selectedUser.address}</Text><br />
                                        <Text><strong>Phone Number:</strong> {selectedUser.phoneNumber}</Text><br />
                                        <Text><strong>Email:</strong> {selectedUser.email}</Text><br />
                                    </Col>
                                    <Col span={12}>
                                        <Text><strong>City:</strong> {selectedUser.city}</Text><br />
                                        <Text><strong>Date of Birth:</strong> {new Date(selectedUser.dateOfBirth).toLocaleDateString('en-GB', {
                                            day: '2-digit',
                                            month: 'short',
                                            year: 'numeric'
                                        })}</Text><br />
                                        <Text><strong>NIC Number:</strong> {selectedUser.nicNumber}</Text><br />
                                        <Text><strong>Marital Status:</strong> {selectedUser.maritalStatus}</Text><br />
                                        <Text><strong>Driving License:</strong> {selectedUser.drivingLicense}</Text><br />
                                    </Col>
                                </Row>
                            </Card>

                            {/* Employment Background */}
                            <Card bordered style={{ marginBottom: '20px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
                                <Title level={4}>Employment Background</Title>
                                <Divider />
                                {selectedUser.education?.map((edu, index) => (
                                    <Row key={index} gutter={[16, 16]} style={{ marginBottom: '1%' }}>
                                        <Col span={12}>
                                            <Text><strong>Degree/Course:</strong> {edu.degree}</Text><br />
                                            <Text><strong>Institute:</strong> {edu.institute}</Text><br />
                                            <Text><strong>Year of Graduation:</strong> {edu.yearOfGraduation}</Text><br />
                                            <Text><strong>Grade:</strong> {edu.grade}</Text><br />
                                            <Text><strong>City:</strong> {edu.city}</Text>
                                        </Col>
                                    </Row>
                                ))}
                            </Card>

                            {/* Employment History */}
                            <Card bordered style={{ marginBottom: '20px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
                                <Title level={4}>Employment History</Title>
                                <Divider />
                                {selectedUser.employment?.map((emp, index) => (
                                    <Row key={index} gutter={[16, 16]} style={{ marginBottom: '1%' }}>
                                        <Col span={12}>
                                            <Text><strong>Company:</strong> {emp.company}</Text><br />
                                            <Text><strong>Position:</strong> {emp.position}</Text><br />
                                            <Text><strong>Year:</strong> {emp.year}</Text><br />
                                            <Text><strong>Reason for Leaving:</strong> {emp.reasonForLeaving}</Text>
                                        </Col>
                                    </Row>
                                ))}
                            </Card>

                            {/* Desired Employment */}
                            <Card bordered style={{ marginBottom: '20px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
                                <Title level={4}>Desired Employment</Title>
                                <Divider />
                                <Row>
                                    <Col span={24}>
                                        <Text><strong>Position Applying For:</strong> {selectedUser.positionApplying}</Text><br />
                                        <Text><strong>Desired Salary:</strong> {selectedUser.desiredSalary}</Text><br />
                                        <Text><strong>Date of Joining:</strong> {new Date(selectedUser.dateOfJoining).toLocaleDateString('en-GB', {
                                            day: '2-digit',
                                            month: 'short',
                                            year: 'numeric'
                                        })}</Text>
                                    </Col>
                                </Row>
                            </Card>

                            {/* Skills and Training */}
                            <Card bordered style={{ marginBottom: '20px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
                                <Title level={4}>Skills and Training</Title>
                                <Divider />
                                {selectedUser.skillTraining?.map((train, index) => (
                                    <Row key={index} gutter={[16, 16]} style={{ marginBottom: '1%' }}>
                                        <Col span={12}>
                                            <Text><strong>Training:</strong> {train.training}</Text><br />
                                            <Text><strong>Level:</strong> {train.level}</Text><br />
                                            <Text><strong>Year:</strong> {train.year}</Text><br />
                                            <Text><strong>Institute:</strong> {train.institute}</Text>
                                        </Col>
                                    </Row>
                                ))}
                            </Card>

                            {/* Payment Information */}
                            <Card bordered style={{ marginBottom: '20px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
                                <Title level={4}>Payment Information</Title>
                                <Divider />
                                <Row>
                                    <Col span={24}>
                                        <Text><strong>Payment Status: </strong>
                                            <Tag color={
                                                selectedUser.payment_status === 'success' ? 'success' :
                                                    selectedUser.payment_status === 'pending' ? 'processing' :
                                                        'error'
                                            }
                                                icon={
                                                    selectedUser.payment_status === 'success' ? <CheckCircleOutlined /> :
                                                        selectedUser.payment_status === 'pending' ? <SyncOutlined spin /> :
                                                            <CloseCircleOutlined />
                                                }

                                            >
                                                {selectedUser.payment_status.toUpperCase()}
                                            </Tag>
                                        </Text><br />
                                        <Text><strong>Transaction Reference:</strong> {selectedUser.transaction_ref}</Text><br />
                                        <Text><strong>Registration Date:</strong> {' '}
                                            {selectedUser.date_of_registration?.$date?.$numberLong
                                                ? new Date(parseInt(selectedUser.date_of_registration.$date.$numberLong)).toLocaleDateString('en-GB', {
                                                    day: '2-digit',
                                                    month: 'short',
                                                    year: 'numeric'
                                                })
                                                : new Date(selectedUser.date_of_registration).toLocaleDateString('en-GB', {
                                                    day: '2-digit',
                                                    month: 'short',
                                                    year: 'numeric'
                                                })}
                                        </Text>
                                    </Col>
                                </Row>
                            </Card>
                        </div>
                    )}
                </Modal>

                {/* Edit User Modal */}
                <Modal
                    title="Edit User"
                    open={editModalVisible}
                    onCancel={() => setEditModalVisible(false)}
                    footer={null}
                >
                    <Form
                        form={form}
                        layout="vertical"
                        onFinish={handleEditUser}
                    >
                        <Form.Item
                            name="fullName"
                            label="Full Name"
                            rules={[{ required: true, message: 'Please input full name' }]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            name="email"
                            label="Email"
                            rules={[{ required: true, type: 'email', message: 'Please input valid email' }]}
                        >
                            <Input />
                        </Form.Item>
                        <PhoneNumberInput />
                        <Form.Item
                            name="positionApplying"
                            label="Position"
                            rules={[{ required: true, message: 'Please select position' }]}
                        >
                            <Select
                                showSearch
                                placeholder="Select position"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option?.label?.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                    option?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {getPositionOptions().map(category => (
                                    <Select.OptGroup key={category.label} label={category.label}>
                                        {category.options.map(option => (
                                            <Option key={option.value} value={option.value}>
                                                {option.label}
                                            </Option>
                                        ))}
                                    </Select.OptGroup>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item
                            name="nationality"
                            label="Nationality"
                            rules={[{ required: true, message: 'Please select nationality' }]}
                        >
                            <Select
                                showSearch
                                placeholder="Select nationality"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {getNationalityOptions().map(option => (
                                    <Option key={option.value} value={option.value}>
                                        {option.label}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item
                            name="payment_status"
                            label="Payment Status"
                            rules={[{ required: true, message: 'Please select payment status' }]}
                        >
                            <Select>
                                <Option value="pending">Pending</Option>
                                <Option value="success">Completed</Option>
                                <Option value="failed">Failed</Option>
                            </Select>
                        </Form.Item>
                        <Form.Item>
                            <Button type="primary" htmlType="submit">
                                Update User
                            </Button>
                        </Form.Item>
                    </Form>
                </Modal>

                {/* Interview Scheduling Modal */}
                <Modal
                    title="Schedule Interview"
                    open={scheduleModalVisible}
                    onCancel={() => setScheduleModalVisible(false)}
                    onOk={handleScheduleInterview}
                    okText="Schedule Interview"
                >
                    <Form layout="vertical">
                        <Form.Item label="Interview Date" required>
                            <DatePicker
                                style={{ width: '100%' }}
                                onChange={setSelectedDate}
                                disabledDate={date => date.isBefore(dayjs(), 'day')}
                            />
                        </Form.Item>
                        <Form.Item label="Interview Time" required>
                            <TimePicker
                                style={{ width: '100%' }}
                                format="HH:mm"
                                onChange={setSelectedTime}
                                minuteStep={15}
                            />
                        </Form.Item>
                        <Form.Item label="Notes">
                            <Input.TextArea
                                rows={4}
                                value={interviewNotes}
                                onChange={e => setInterviewNotes(e.target.value)}
                                placeholder="Add any notes about the interview..."
                            />
                        </Form.Item>
                    </Form>
                </Modal>
            </Card>
        </div>
    );
};

export default UserManagementDashboard;