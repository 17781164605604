import React from 'react';
import { Carousel, Typography, Row, Col } from 'antd';

import managementServiceImg from '../images/management-services.jpg';
import telecomServiceImg from '../images/telecom-services.jpg';
import marketingServiceImg from '../images/marketing-and-strategy.jpg';
import informationTechnologyImg from '../images/information-technology.jpg';
import contructionManagementImg from '../images/construction-and-management.jpg';
import utilityMangementImg from '../images/utility-management.jpg';
import equitementRentalImg from '../images/equipment-rental.jpg';
import trainingConsultancyImg from '../images/training-and-consultancy.jpg';
import recruitmentServiceImg from '../images/recruitment-services.jpg';
import securitySolutionsImg from '../images/security-solutions.jpg';

import { LeftCircleFilled, RightCircleFilled } from '@ant-design/icons';
import '../App.css';
const { Title, Text } = Typography;


const services = [
    {
        title: "Management Service",
        description: "Offering expert management solutions and skilled manpower to drive your success.",
        image: managementServiceImg,
    },
    {
        title: "Telecom Services",
        description:
            "Providing advanced solutions in network management, cloud migration, support, server maintenance, and IT security to meet the telecom industry’s evolving needs.",
        image: telecomServiceImg,
    },
    {
        title: "Marketing and Strategy",
        description:
            "Developing tailored strategies to boost sales, drive growth, exceed customer expectations, and strengthen brand recognition.",
        image: marketingServiceImg,
    },
    {
        title: "Information Technology",
        description:
            "Providing innovative solutions in custom applications, embedded systems, wireless devices, and top-notch support.",
        image: informationTechnologyImg,
    },
    {
        title: "Construction and Maintenance",
        description:
            "Reliable repairs, renovations, and maintenance with minimal business disruption..",
        image: contructionManagementImg,
    },
    {
        title: "Utility Management",
        description:
            "Streamlining utility billing, submetering, and expense management for multifamily properties, with a focus on energy efficiency and benchmarking.",
        image: utilityMangementImg,
    },
    {
        title: "Equipment Rentals",
        description:
            "Offering a wide range of modern, safe material handling equipment at competitive rates.",
        image: equitementRentalImg,
    },
    {
        title: "Training and Consultancy",
        description:
            "Delivering practical, interactive training programs across public, private, and social sectors to drive growth and transformation.",
        image: trainingConsultancyImg,
    },
    {
        title: "Recruitment Services",
        description:
            "Providing fast, professional staffing solutions, including HR services, marketing, international recruitment, and training for both skilled and unskilled manpower.",
        image: recruitmentServiceImg,
    },
    {
        title: "Security Solutions",
        description:
            "Offering professional outsourcing of security guards with recruitment services for both Saudi and non-Saudi nationals, along with complete security guard fleet solutions.",
        image: securitySolutionsImg,
    },
];

const Services = () => {
    return (
        <div id="services" style={{ textAlign: "center", padding: "30px" }}>
            <Title level={2} style={{ marginBottom: 20, fontFamily: 'Montserrat, sans-serif', fontSize: '35px', fontWeight: 'bold' }}>
                Our Services
            </Title>
            <Carousel
                infinite={true}
                autoplay={true}
                autoplaySpeed={5000}
                draggable={true}
                effect='scrollx'
                dots={false}
                arrows={true}
                prevArrow={<LeftCircleFilled />}
                nextArrow={<RightCircleFilled />}
                slidesToShow={4} // Show 4 items at a time
                responsive={[
                    {
                        breakpoint: 768,
                        settings: {
                            slidesToShow: 1, // Mobile view: Show 1 at a time
                        },
                    },
                    {
                        breakpoint: 1024,
                        settings: {
                            slidesToShow: 2, // Tablet view: Show 2 at a time
                        },
                    },
                    {
                        breakpoint: 1440,
                        settings: {
                            slidesToShow: 4, // Desktop view: Show 4 at a time
                        },
                    },
                ]}
            >
                {services.map((service, index) => (
                    <div key={index} style={{ padding: "10px" }}>
                        <Row justify="center" align="middle">
                            <Col>
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "space-between", // Even spacing between elements
                                        alignItems: "center",
                                        backgroundColor: 'white',
                                        borderRadius: "10px",
                                        height: "300px", // Fixed height for uniformity
                                        padding: "20px", // Padding to create space around content
                                    }}
                                >
                                    <img
                                        className='card-image'
                                        src={service.image}
                                        alt={service.title}
                                        style={{
                                            width: 120,
                                            height: 120,
                                            borderRadius: "50%",
                                            objectFit: "cover",
                                        }}
                                    />
                                    <Title
                                        level={4}
                                        style={{
                                            fontFamily: "Montserrat, sans-serif",
                                            fontWeight: "bold",
                                            fontSize: "20px",
                                            textAlign: "center",
                                            whiteSpace: "nowrap",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            marginBottom: "auto",
                                        }}
                                    >
                                        {service.title}
                                    </Title>
                                    <Text
                                        style={{
                                            fontSize: "14px",
                                            fontFamily: "Montserrat, sans-serif",
                                            color: "black",
                                            textAlign: "center",
                                            overflow: "hidden",
                                            display: "flex",
                                            justifyContent: "center",
                                        }}
                                    >
                                        {service.description}
                                    </Text>
                                </div>
                            </Col>
                        </Row>
                    </div>
                ))}
            </Carousel>
        </div >
    );
};

export default Services;
