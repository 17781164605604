import React, { useState } from 'react';
import { Layout, Menu, Button, Drawer } from 'antd';
import { MenuOutlined, FormOutlined } from '@ant-design/icons';
import { useMediaQuery } from 'react-responsive';
import logo from '../images/logo.png';
import { primaryColor, secondaryColor } from '../constants/colors.js';

const Navbar = () => {
    const [drawerVisible, setDrawerVisible] = useState(false);

    // Media query hooks
    const isMobile = useMediaQuery({ maxWidth: 767 });
    const isDesktop = useMediaQuery({ minWidth: 768 });

    // Menu items
    const menuItems = [
        { key: 'home', label: 'Home' },
        { key: 'about', label: 'About' },
        { key: 'services', label: 'Services' },
        { key: 'contact-us', label: 'Contact Us' },
        { key: 'apply', label: 'APPLY NOW', type: 'button', icon: <FormOutlined />, style: { backgroundColor: primaryColor, color: primaryColor, borderRadius: '30px' } }
    ];

    // Auto change menu item based on scroll position if not on mobile
    if (!isMobile) {
        try {
            // window.onscroll = () => {
            //     const scrollPos = window.scrollY + 100;
            //     const sectionIds = ['home', 'about', 'services', 'contact'];
            //     const sections = sectionIds.map(id => document.getElementById(id));
            //     const menuItems = document.querySelectorAll('.ant-menu-item');

            //     sections.forEach((section, index) => {
            //         if (section) {
            //             const sectionTop = section.offsetTop;
            //             const sectionHeight = section.clientHeight;
            //             if (scrollPos >= sectionTop && scrollPos < sectionTop + sectionHeight) {
            //                 menuItems.forEach(item => item.classList.remove('ant-menu-item-selected'));
            //                 menuItems[index].classList.add('ant-menu-item-selected');
            //             }
            //         }
            //     });
            // }

            window.onscroll = () => {
                const scrollPos = window.scrollY + 100; // Adjust offset as needed
                const sectionIds = ['home', 'about', 'services', 'contact-us', 'apply-form'];
                const sections = sectionIds.map(id => document.getElementById(id));
                const menuItems = document.querySelectorAll('.ant-menu-item');

                sections.forEach((section, index) => {
                    if (section) {
                        const sectionTop = section.offsetTop;
                        const sectionHeight = section.clientHeight;

                        if (scrollPos >= sectionTop && scrollPos < sectionTop + sectionHeight) {
                            // Remove 'ant-menu-item-selected' from all items
                            menuItems.forEach(item => item?.classList?.remove('ant-menu-item-selected'));

                            // Add 'ant-menu-item-selected' to the current item, if it exists
                            if (menuItems[index]) {
                                menuItems[index].classList.add('ant-menu-item-selected');
                            }
                        }
                    }
                });
            };
        }
        catch (error) {
            console.log(error);
        }
    }


    // Handle item click
    const handleMenuClick = (e) => {
        switch (e.key) {
            case 'home':
                const homeSection = document.getElementById('home');

                if (homeSection) {
                    console.log('Scrolling to home section');
                    homeSection.scrollIntoView({ behavior: 'smooth', block: 'start' });
                }
                break;
            case 'about':
                const aboutSection = document.getElementById('about');
                if (aboutSection) {
                    console.log('Scrolling to about section');
                    aboutSection.scrollIntoView({ behavior: 'smooth', block: 'start' });
                }
                break;
            case 'services':
                const servicesSection = document.getElementById('services');
                if (servicesSection) {
                    console.log('Scrolling to services section');
                    servicesSection.scrollIntoView({ behavior: 'smooth', block: 'start' });
                }
                break;
            case 'contact-us':
                const contactSection = document.getElementById('contact-us');
                if (contactSection) {
                    console.log('Scrolling to contact section');
                    contactSection.scrollIntoView({ behavior: 'smooth', block: 'start' });
                }
                break;
            case 'apply':
                const applySection = document.getElementById('apply-form');
                if (applySection) {
                    console.log('Scrolling to apply section');
                    applySection.scrollIntoView({ behavior: 'smooth', block: 'start' });
                }
                break;
            default:
                break;
        }
    };

    const mobileMenuItems = [
        { key: 'home', label: 'Home' },
        { key: 'about', label: 'About' },
        { key: 'services', label: 'Services' },
        { key: 'contact-us', label: 'Contact Us' },
        { key: 'apply', label: 'APPLY NOW', type: 'button', style: { backgroundColor: primaryColor, color: 'black', borderRadius: '30px' } }
    ];
    // Logo component
    const Logo = () => (
        <div style={{
            textAlign: isMobile ? 'center' : 'left',
            alignItems: 'center',
            display: 'flex',
            justifyContent: isMobile ? 'center' : 'flex-start',
        }}>
            <img src={logo} alt="Logo" style={{ height: '80px', backgroundColor: 'white', cursor: 'pointer' }} onClick={
                () => {
                    window.location.href = '/';
                }
            } />
        </div>
    );

    // Desktop Navbar
    const DesktopNavbar = () => (
        <Layout.Header
            style={{
                backgroundColor: 'white',
                display: 'flex',
                // position: 'fixed',
                // top: 0,
                width: '100%',
                zIndex: 1000,
                boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
                alignItems: 'center',
                justifyContent: 'space-between',
                padding: '0 50px',
                height: '100px',
            }}
        >
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <Logo />
            </div>
            <Menu
                mode="horizontal"
                items={menuItems}
                style={{
                    flex: 1,
                    justifyContent: 'flex-end',
                    borderBottom: 'none',
                    fontWeight: 'bold',
                    color: secondaryColor,
                    fontSize: '16px',
                    backgroundColor: 'white',
                }}
                onClick={handleMenuClick}
            />
        </Layout.Header>
    );

    // Mobile Navbar
    const MobileNavbar = () => (
        <Layout.Header
            style={{
                width: '100%',
                backgroundColor: 'white',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                height: '100px',
                padding: '10px 0',
            }}
        >
            <Logo />
            <Button
                icon={<MenuOutlined />}
                onClick={() => setDrawerVisible(true)}
                style={{ position: 'absolute', right: '15px', top: '15px' }}
            />
            <Drawer
                title="Menu"
                placement="right"
                onClose={() => setDrawerVisible(false)}
                visible={drawerVisible}
            >
                <Menu
                    mode="vertical"
                    items={mobileMenuItems}
                    onClick={(e) => {
                        setDrawerVisible(false);
                        handleMenuClick(e);
                    }}
                    style={{
                        backgroundColor: 'white',
                        border: 'none',
                    }}
                />
            </Drawer>
        </Layout.Header>
    );

    return (
        <Layout>
            {isMobile && <MobileNavbar />}
            {isDesktop && <DesktopNavbar />}
        </Layout>
    );
};

export default Navbar;