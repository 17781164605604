import React, { useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

import {
    Form,
    Input,
    Button,
    Select,
    Divider,
    DatePicker,
    Card,
    Steps,
    Typography,
    Checkbox,
    Row,
    Popover,
    Col
} from 'antd';

import SkillsStep from './FormCoponents/skillsStep';
import PaymentStep from './FormCoponents/PaymentStep';

import { primaryColor } from '../constants/colors';
import { apiBaseUrl } from '../constants/apiDetails';
import { getNationalityOptions } from '../constants/nationalities';
import { getPositionOptions } from '../constants/positions';

const { Title, Text } = Typography;
const { Option } = Select;

const GulfJobApplicationForm = () => {
    const [currentStep, setCurrentStep] = useState(0);
    const [form] = Form.useForm();
    const [skills, setSkills] = useState([]);
    const [formValues, setFormValues] = useState({});

    const [userID, setUserID] = useState(null);

    // State for marital status
    const [maritalStatus, setMaritalStatus] = useState(null);

    const handleMaritalStatusChange = (value) => {
        setMaritalStatus(value);
    };


    const submitApplication = () => {
        form.submit();
    };



    const PersonalInfoStep = () => (
        <Row gutter={[16, 16]}>
            <Col xs={24} sm={24} md={12}>
                <Form.Item
                    name="fullName"
                    label="Full Name"
                    rules={[{ required: true, message: 'Please enter full name' }]}
                >
                    <Input placeholder="Full Name" />
                </Form.Item>
                <Form.Item
                    name="passportNumber"
                    label="Passport Number"
                    rules={[{ required: true, message: 'Please enter passport number' }]}
                >
                    <Input placeholder="Passport Number" />
                </Form.Item>
                <Form.Item
                    name="nationality"
                    label="Nationality"
                    rules={[{ required: true, message: 'Please select nationality' }]}
                >
                    <Select
                        showSearch
                        placeholder="Select Nationality"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        {getNationalityOptions().map(option => (
                            <Option key={option.value} value={option.value}>
                                {option.label}
                            </Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item
                    name="address"
                    label="Address"
                    rules={[{ required: true, message: 'Please enter address' }]}
                >
                    <Input placeholder="Address" />
                </Form.Item>
                <Form.Item
                    name="phoneNumber"
                    label="Phone Number"
                    rules={[
                        { required: true, message: 'Please enter phone number' },
                        {
                            validator: (_, value) => {
                                if (!value) {
                                    return Promise.reject('Please enter phone number');
                                }
                                if (value.length < 8) {
                                    return Promise.reject('Phone number is too short');
                                }
                                if (value.length > 15) {
                                    return Promise.reject('Phone number is too long');
                                }
                                return Promise.resolve();
                            }
                        }
                    ]}
                >
                    <PhoneInput
                        country={'sa'}
                        enableSearch={true}
                        inputStyle={{ width: '100%', height: '32px' }}
                        containerStyle={{ width: '100%' }}
                    />
                </Form.Item>

                <Form.Item
                    name="drivingLicense"
                    label="Driving License"
                    rules={[{ required: true, message: 'Please select driving license' }]}
                >
                    <Select placeholder="Select Driving License">
                        <Option value="yes">Yes</Option>
                        <Option value="no">No</Option>
                    </Select>
                </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12}>
                <Form.Item
                    name="email"
                    label="Email"
                    rules={[
                        { required: true, message: 'Please enter email' },
                        { type: 'email', message: 'Please enter a valid email' }
                    ]}
                >
                    <Input placeholder="Email" />
                </Form.Item>

                <Form.Item
                    name="city"
                    label="City"
                    rules={[{ required: true, message: 'Please enter city' }]}
                >
                    <Input placeholder="City" />
                </Form.Item>

                <Form.Item
                    name="dateOfBirth"
                    label="Date of Birth"
                    rules={[{ required: true, message: 'Please select date of birth' }]}
                >
                    <DatePicker style={{ width: '100%' }} />
                </Form.Item>

                <Form.Item
                    name="nicNumber"
                    label="ID Number"
                    rules={[{ required: true, message: 'Please enter ID number' }]}
                >
                    <Input placeholder="ID Number" />
                </Form.Item>

                <Form.Item
                    name="maritalStatus"
                    label="Marital Status"
                    rules={[{ required: true, message: 'Please select marital status' }]}
                >
                    <Select placeholder="Select Marital Status"
                        onChange={handleMaritalStatusChange}
                    >
                        <Option value="single">Single</Option>
                        <Option value="married">Married</Option>
                    </Select>
                </Form.Item>
                {form.getFieldValue('maritalStatus') === 'married' && (
                    <Form.Item
                        name="noOfDependents"
                        label="Number of Dependents"
                        rules={[{ required: true, message: 'Please enter number of dependents' }]}
                    >
                        <Input placeholder="Number of Dependents" />
                    </Form.Item>
                )}
            </Col>
        </Row>
    );

    // Other step components would be similarly structured
    const EmploymentBackgroundStep = () => (
        <div>
            <Title level={3}>Educational Background</Title>
            <Form.List
                name="education"
            // rules={[
            //     {
            //         validator: async (_, value) => {
            //             if (!value || value.length < 1) {
            //                 return Promise.reject(new Error('At least one education entry is required'));
            //             }
            //         },
            //     },
            // ]}
            >
                {(fields, { add, remove }) => (
                    <>
                        {fields.map(({ key, name, ...restField }) => (
                            <Card 
                                key={key} 
                                style={{ 
                                    marginBottom: 16,
                                    borderRadius: '8px',
                                    boxShadow: '0 2px 4px rgba(0,0,0,0.05)'
                                }}
                            >
                                <Row gutter={[16, 16]}>
                                    {/* Degree / Course */}
                                    <Col xs={24} sm={24} md={12}>
                                        <Form.Item
                                            {...restField}
                                            name={[name, 'degree']}
                                            label="Degree/Course"
                                            rules={[{ required: true, message: 'Missing degree' }]}
                                        >
                                            <Input placeholder="Degree/Course" />
                                        </Form.Item>
                                    </Col>
                                    {/* University / Institute */}
                                    <Col xs={24} sm={24} md={12}>
                                        <Form.Item
                                            {...restField}
                                            name={[name, 'institute']}
                                            label="University/Institute"
                                            rules={[{ required: true, message: 'Missing institute' }]}
                                        >
                                            <Input placeholder="University/Institute" />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={[16, 16]}>
                                    {/* Year of Graduation */}
                                    <Col xs={24} sm={24} md={6}>
                                        <Form.Item
                                            {...restField}
                                            name={[name, 'yearOfGraduation']}
                                            label="Year of Graduation"
                                            rules={[{ required: true, message: 'Missing year of graduation' }]}
                                        >
                                            <Input placeholder="Year of Graduation" />
                                        </Form.Item>
                                    </Col>
                                    {/* Grade */}
                                    <Col xs={24} sm={24} md={6}>
                                        <Form.Item
                                            {...restField}
                                            name={[name, 'grade']}
                                            label="Grade"
                                            rules={[{ required: true, message: 'Missing grade' }]}
                                        >
                                            <Input placeholder="Grade" />
                                        </Form.Item>
                                    </Col>
                                    {/* City */}
                                    <Col xs={24} sm={24} md={6}>
                                        <Form.Item
                                            {...restField}
                                            name={[name, 'city']}
                                            label="City"
                                            rules={[{ required: true, message: 'Missing city' }]}
                                        >
                                            <Input placeholder="City" />
                                        </Form.Item>
                                    </Col>
                                    </Row>
                                    <Row gutter={[16, 16]}>
                                    {/* Delete button */}
                                    <Col xs={24} sm={24} md={6}>
                                        <Button style={{ width: '100%' , alignItems: 'center' }}
                                            danger
                                            onClick={() => remove(name)}
                                        // disabled={fields.length === 1} // Disable if only one entry left
                                        >
                                            Delete
                                        </Button>
                                    </Col>

                                </Row>
                            </Card>
                        ))}
                        <Button type="dashed" onClick={() => add()} block>
                            Add Education
                        </Button>
                    </>
                )}
            </Form.List>
        </div>
    );

    const EmploymentHistoryStep = () => (
        <div>
            <Title level={3}>Employment History</Title>
            <Form.List
                name="employment"
            // rules={[
            //     {
            //         validator: async (_, value) => {
            //             if (!value || value.length < 1) {
            //                 return Promise.reject(new Error('At least one employment entry is required'));
            //             }
            //         },
            //     },
            // ]}
            >
                {(fields, { add, remove }) => (
                    <>
                        {fields.map(({ key, name, ...restField }) => (
                             <Card 
                             key={key} 
                             style={{ 
                                 marginBottom: 16,
                                 borderRadius: '8px',
                                 boxShadow: '0 2px 4px rgba(0,0,0,0.05)'
                             }}
                         >
                            <Row key={key} gutter={[16, 16]} align="middle">
                                {/* Company */}
                                <Col xs={24} sm={24} md={6}>
                                    <Form.Item
                                        {...restField}
                                        name={[name, 'company']}
                                        label="Company"
                                        rules={[{ required: true, message: 'Missing company' }]}
                                    >
                                        <Input placeholder="Company" />
                                    </Form.Item>
                                </Col>
                                {/* Position */}
                                <Col xs={24} sm={24} md={6}>
                                    <Form.Item
                                        {...restField}
                                        name={[name, 'position']}
                                        label="Position"
                                        rules={[{ required: true, message: 'Missing position' }]}
                                    >
                                        <Input placeholder="Position" />
                                    </Form.Item>
                                </Col>
                                {/* Year */}
                                <Col xs={24} sm={24} md={6}>
                                    <Form.Item
                                        {...restField}
                                        name={[name, 'year']}
                                        label="Year"
                                        rules={[{ required: true, message: 'Missing year' }]}
                                    >
                                        <Input placeholder="Year" />
                                    </Form.Item>
                                </Col>
                                {/* Reason for Leaving */}
                                <Col xs={24} sm={24} md={6}>
                                    <Form.Item
                                        {...restField}
                                        name={[name, 'reasonForLeaving']}
                                        label="Reason for Leaving "
                                    >
                                        <Input placeholder="Reason for Leaving" />
                                    </Form.Item>
                                </Col>
                                </Row>
                                <Row gutter={[16, 16]}>
                                
                                {/* Delete button */}
                                <Col xs={24} sm={24} md={6}>
                                    <Button
                                        danger
                                        onClick={() => remove(name)}
                                    // disabled={fields.length === 1} // Disable if only one entry left
                                    >
                                        Delete
                                    </Button>
                                </Col>
                            </Row>
                            </Card>
                        ))}
                        <Button type="dashed" onClick={() => add()} block>
                            Add Employment History
                        </Button>
                    </>
                )}
            </Form.List>
        </div>
    );

    const SS = () => (
        <Form>
            <SkillsStep skills={skills} setSkills={setSkills} />
        </Form>
    );

    const DesiredEmploymentStep = () => (
        <div style={{ marginBottom: '24px' }}>
            <Title level={3}>Desired Employment</Title>
            <Row gutter={[16, 16]} align="middle">
                {/* Position Applying For */}
                <Col xs={24} sm={24} md={8}>
                    <Form.Item
                        name="positionApplying"
                        label="Position Applying For"
                        rules={[{ required: true, message: 'Please select the position' }]}
                    >
                        <Select
                            showSearch
                            placeholder="Select Position"
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                                option?.label?.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                option?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {getPositionOptions().map(category => (
                                <Select.OptGroup key={category.label} label={category.label}>
                                    {category.options.map(option => (
                                        <Option key={option.value} value={option.value}>
                                            {option.label}
                                        </Option>
                                    ))}
                                </Select.OptGroup>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
                {/* Desired Salary */}
                <Col xs={24} sm={24} md={8}>
                    <Form.Item
                        name="desiredSalary"
                        label="Desired Salary"
                        rules={[{ required: true, message: 'Please enter your desired salary' }]}
                    >
                        <Input prefix="SAR(﷼)" placeholder="Salary Expectation" />
                    </Form.Item>
                </Col>
                {/* Date of Joining */}
                <Col xs={24} sm={24} md={8}>
                    <Form.Item
                        name="dateOfJoining"
                        label="Date of Joining"
                        rules={[{ required: true, message: 'Please select the date' }]}
                    >
                        <DatePicker style={{ width: '100%' }} />
                    </Form.Item>
                </Col>
            </Row>
        </div>
    );

    const SkillsTrainingStep = () => (
        <Form.List name="skillTraining">
            {(fields, { add, remove }) => (
                <>
            <Title level={3}>
                Skills and Training
            </Title>
                    {fields.map(({ key, name, ...restField }) => (
                        <Row key={key} gutter={[16, 16]}>
                            {/* Training */}
                            <Col xs={18} sm={10} md={4}>
                                <Form.Item
                                    {...restField}
                                    name={[name, 'training']}
                                    label="Training"
                                    rules={[{ required: true, message: 'Please enter the training/achievement' }]}
                                >
                                    <Input placeholder="Training/Achievement" />
                                </Form.Item>
                            </Col>

                            {/* Level */}
                            <Col xs={14} sm={12} md={4}>
                                <Form.Item
                                    {...restField}
                                    name={[name, 'level']}
                                    label="Level"
                                >
                                    <Input placeholder="Level" />
                                </Form.Item>
                            </Col>

                            {/* Year */}
                            <Col xs={8} sm={5} md={2}>
                                <Form.Item
                                    {...restField}
                                    name={[name, 'year']}
                                    label="Year"
                                    rules={[{ required: true, message: 'Please select the year' }]}
                                >
                                    <Input placeholder="Year" />
                                </Form.Item>
                            </Col>

                            {/* Institute (Optional) */}
                            <Col xs={14} sm={12} md={4}>
                                <Form.Item
                                    {...restField}
                                    name={[name, 'institute']}
                                    label="Institute"
                                >
                                    <Input placeholder="Institute" />
                                </Form.Item>
                            </Col>

                            {/* Delete Button */}
                            <Col xs={6} sm={6} md={2}>
                                <Button
                                    danger
                                    onClick={() => remove(name)}
                                    block
                                    style={{ marginTop: '32px' }} // Adjust margin to align with input fields
                                >
                                    Delete
                                </Button>
                            </Col>
                        </Row>
                    ))}
                    <Button type="dashed" onClick={() => add()} block>
                        Add Training
                    </Button>
                </>
            )}
        </Form.List>
    );

    const ReferralStep = () => (
        <div>
            <Title level={4}>How Did You Hear About Us?</Title>
            <Row gutter={16}>
                {/* Referral Method Select (Multiple) */}
                <Col span={24}>
                    <Form.Item
                        name="referralMethod"
                        label="How did you hear about us?"
                        rules={[{ required: true, message: 'Please select at least one option' }]}
                    >
                        <Select
                            mode="multiple"
                            placeholder="Select all that apply"
                            style={{ width: '100%' }}
                        >
                            <Select.Option value="newspaper">Newspaper</Select.Option>
                            <Select.Option value="socialMedia">Social Media</Select.Option>
                            <Select.Option value="friendsFamily">Friends or Family</Select.Option>
                            <Select.Option value="instagram">Instagram</Select.Option>
                            <Select.Option value="facebook">Facebook</Select.Option>
                            <Select.Option value="googleSearch">Google Search</Select.Option>
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
        </div>
    );

    const PreviewStep = () => (
        <div style={{ padding: '20px', backgroundColor: '#f9f9f9', borderRadius: '8px' }}>
            <Title level={3} style={{ textAlign: 'center', marginBottom: '20px' }}>
                Preview Your Details
            </Title>

            <Card bordered style={{ marginBottom: '20px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
                <Title level={4}>Personal Information</Title>
                <Divider />
                <Row gutter={[16, 16]}>
                    <Col span={12}>
                        <Text ><strong>Full Name:</strong> {formValues.fullName}</Text><br />
                        <Text><strong>Passport Number:</strong> {formValues.passportNumber}</Text><br />
                        <Text><strong>Nationality:</strong> {formValues.Nationality}</Text><br />
                        <Text><strong>Address:</strong> {formValues.address}</Text><br />
                        <Text><strong>Phone Number:</strong> {formValues.phoneNumber}</Text><br />
                        <Text><strong>Email:</strong> {formValues.email}</Text><br />
                    </Col>
                    <Col span={12}>
                        <Text><strong>City:</strong> {formValues.city}</Text><br />
                        <Text><strong>Date of Birth:</strong> {formValues.dateOfBirth?.format('YYYY-MM-DD')}</Text><br />
                        <Text><strong>NIC Number:</strong> {formValues.nicNumber}</Text><br />
                        <Text><strong>Marital Status:</strong> {formValues.maritalStatus}</Text><br />
                        {formValues.maritalStatus === 'married' && (
                            <Text><strong>Number of Dependents:</strong> {formValues.noOfDependents}</Text>
                        )}

                        {formValues.maritalStatus !== 'married' && (
                            <Text><strong>Number of Dependents:</strong> -- </Text>
                        )}

                        <br />
                        <Text><strong>Driving License:</strong> {formValues.drivingLicense}</Text>
                    </Col>
                </Row>
            </Card>

            {/* Employment Background */}
            <Card bordered style={{ marginBottom: '20px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
                <Title level={4}>Employment Background</Title>
                <Divider />
                {formValues.education?.map((edu, index) => (
                    <Row key={index} gutter={[16, 16]} style={{ marginBottom: '1%' }}>
                        <Col span={12}>
                            <Text><strong>Degree/Course:</strong> {edu.degree}</Text><br />
                            <Text><strong>Institute:</strong> {edu.institute}</Text><br />
                            <Text><strong>Year of Graduation:</strong> {edu.yearOfGraduation}</Text><br />
                            <Text><strong>Grade:</strong> {edu.grade}</Text><br />
                            <Text><strong>City:</strong> {edu.city}</Text>
                        </Col>
                    </Row>
                ))}
            </Card>

            {/* Employment History */}
            <Card bordered style={{ marginBottom: '20px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
                <Title level={4}>Employment History</Title>
                <Divider />
                {formValues.employment?.map((emp, index) => (
                    <Row key={index} gutter={[16, 16]} style={{ marginBottom: '1%' }}>
                        <Col span={12}>
                            <Text><strong>Company:</strong> {emp.company}</Text><br />
                            <Text><strong>Position:</strong> {emp.position}</Text><br />
                            <Text><strong>Year:</strong> {emp.year}</Text><br />
                            <Text><strong>Reason for Leaving:</strong> {emp.reasonForLeaving}</Text>
                        </Col>
                    </Row>
                ))}
            </Card>

            {/* Desired Employment */}
            <Card bordered style={{ marginBottom: '20px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
                <Title level={4}>Desired Employment</Title>
                <Divider />
                <Row>
                    <Col span={24}>
                        <Text><strong>Position Applying For:</strong> {formValues.positionApplying}</Text><br />
                        <Text><strong>Desired Salary:</strong> {formValues.desiredSalary}</Text><br />
                        <Text><strong>Date of Joining:</strong> {formValues.dateOfJoining?.format('YYYY-MM-DD')}</Text>
                    </Col>
                </Row>
            </Card>

            {/* Skills and Training */}
            <Card bordered style={{ marginBottom: '20px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
                <Title level={4}>Skills and Training</Title>
                <Divider />
                {formValues.skillTraining?.map((train, index) => (
                    <Row key={index} gutter={[16, 16]} style={{ marginBottom: '1%' }}>
                        <Col span={12}>
                            <Text><strong>Training:</strong> {train.training}</Text><br />
                            <Text><strong>Level:</strong> {train.level}</Text><br />
                            <Text><strong>Year:</strong> {train.year}</Text><br />
                            <Text><strong>Institute:</strong> {train.institute}</Text>
                        </Col>
                    </Row>
                ))}
            </Card>

            {/* Confirmation Checkbox */}
            <div style={{ marginTop: '20px', textAlign: 'left' }}>
                <Form.Item
                    name="confirm"
                    valuePropName="checked"
                    rules={[
                        {
                            validator: (_, value) =>
                                value ? Promise.resolve() : Promise.reject('Please confirm the details'),
                        },
                    ]}
                >
                    <Checkbox>
                        I confirm that the details provided are accurate and complete. I understand that any false statements or omissions may result in disqualification or termination of my application.
                    </Checkbox>
                </Form.Item>
            </div>
        </div>
    );


    const steps = [
        // {
        //     title: 'test',
        //     content: <PaymentStep userID={userID} />
        // },
        {
            title: 'Info',
            // icon: <UserOutlined />,
            content: <PersonalInfoStep />
            // content: <PaymentStep />
        },
        {
            title: 'Education',
            // icon: <FileTextOutlined />,
            content: <EmploymentBackgroundStep />
        },
        {
            title: 'Employment',
            // icon: <BankOutlined />,
            content: <EmploymentHistoryStep />
        },
        {
            title: 'Skills',
            // icon: <BranchesOutlined />,
            content: <SS />
        },
        {
            title: 'Target Role',
            // icon: <DollarOutlined />,
            content: <DesiredEmploymentStep />
        },
        {
            title: 'Training',
            // icon: <BookOutlined />,
            content: <SkillsTrainingStep />
        },
        {
            title: 'Referral',
            // icon: <ContactsOutlined />,
            content: <ReferralStep />
        },
        {
            title: 'Preview',
            // icon: <DollarOutlined />,
            content: <PreviewStep />
        },
        {
            title: 'Payment',
            // icon: <DollarOutlined />,
            content: <PaymentStep userID={userID} />
        }
    ];

    const saveUserData = (userData) => {
        const apiUrl = apiBaseUrl + "/registerUser";
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(userData)
        };

        fetch(apiUrl, requestOptions).then(response => {

            if (response.ok) {
                response.json().then(data => {
                    setUserID(data.id);
                });

            } else {
                console.error('Failed to save user data');
            }

        }).catch(error => {
            console.error('Error saving user data:', error);
        });
    };


    const nextStep = () => {
        form.validateFields().then(() => {
            const formData = {
                ...form.getFieldsValue(true),
                skills,
            };

            setFormValues((prev) => ({ ...prev, ...formData })); // To store form values for preview it will replace the previous values with new values

            setCurrentStep(currentStep + 1);

            if (steps[currentStep + 1].title === "Payment") {
                console.log("Saving Your Data");
                saveUserData(formValues);
            }


        }).catch((errorInfo) => {
            console.log('Validation Failed:', errorInfo);
        });
    };

    const prevStep = () => {
        setCurrentStep(currentStep - 1);
    };

    const onFinish = () => {
        // Collect complete form data including skills
        const completeData = {
            ...form.getFieldsValue(true), // Collect other form data
            skills, // Add skills to the final data
        };

        console.log('Complete form values:', completeData);

        // Example: Preparing FormData for backend submission
        const formData = new FormData();
        for (const key in completeData) {
            if (Array.isArray(completeData[key])) {
                // Handle array data (e.g., employment history, skills, etc.)
                completeData[key].forEach((item, index) => {
                    if (typeof item === 'object') {
                        Object.keys(item).forEach((subKey) => {
                            formData.append(`${key}[${index}][${subKey}]`, item[subKey]);
                        });
                    } else {
                        formData.append(`${key}[${index}]`, item);
                    }
                });
            } else {
                formData.append(key, completeData[key]);
            }
        }

        // Log FormData for debugging
        for (const [key, value] of formData.entries()) {
            // console.log(key, value);
            console.log('Key:', key);
        }
    };

    const customDot = (dot, { status, index }) => (
        <Popover
          content={
            <span>
              step {index} status: {status}
            </span>
          }
        >
          {dot}
        </Popover>
      );

    return (
        <div id='apply-form' style={{ padding: '16px' }}>
            <div style={{ backgroundColor: '#f5f5f5', width: '100%', height: '1px', marginBottom: '20px', borderRadius: '10px' }} />
            <Title level={1} style={{ 
                textAlign: 'center', 
                marginBottom: 30,
                fontFamily: 'Montserrat, sans-serif',
                fontSize: 'clamp(24px, 5vw, 35px)',
                fontWeight: 'bold'
            }}>
                Application Form
            </Title>
            <Card
                style={{
                    width: '80%',
                    maxWidth: '80%',
                    margin: '0 auto',
                    marginBottom: '4%',
                    padding: '16px',
                    boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
                    borderRadius: '8px'
                }}
            >
                <Steps 
                    current={currentStep} 
                    // progressDot={customDot}
                    style={{ 
                        marginBottom: 30,
                        overflowX: 'auto',
                        padding: '8px 0'
                    }} 
                    size="small"
                    responsive={true}
                >
                    {steps.map(step => (
                        <Steps.Step
                            key={step.title}
                            title={step.title}
                            icon={step.icon}
                        />
                    ))}
                </Steps>

                <Form 
                    form={form} 
                    layout="vertical" 
                    onFinish={onFinish}
                    requiredMark="optional"
                    style={{ width: '100%' }}
                >
                    <div style={{ minHeight: '400px' }}>
                        {steps[currentStep].content}
                    </div>

                    <div style={{
                        marginTop: 30,
                        display: 'flex',
                        justifyContent: 'space-between',
                        flexWrap: 'wrap',
                        gap: '16px'
                    }}>
                        {currentStep > 0 && (
                            <Button 
                                onClick={prevStep} 
                                style={{ 
                                    backgroundColor: primaryColor, 
                                    color: 'white',
                                    minWidth: '120px',
                                    height: '40px'
                                }}
                            >
                                Previous
                            </Button>
                        )}

                        {currentStep < steps.length - 1 && (
                            <Button 
                                type="primary" 
                                onClick={nextStep} 
                                style={{ 
                                    backgroundColor: primaryColor, 
                                    color: 'white',
                                    minWidth: '120px',
                                    height: '40px'
                                }}
                            >
                                Next
                            </Button>
                        )}
                    </div>
                </Form>
            </Card>
        </div>
    );
};

export default GulfJobApplicationForm;