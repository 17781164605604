import React from 'react';
import {
    Typography,
    Row,
    Col,
    Card,
    Space,
    Divider
} from 'antd';
import {
    MailOutlined,
    PhoneOutlined,
    HomeOutlined,
    LinkedinOutlined,
    TwitterOutlined,
    InstagramOutlined
} from '@ant-design/icons';

const { Title, Text, Paragraph } = Typography;

const ContactUsPage = () => {
    return (
        <div id='contact-us' className='block contactBlock' style={{ backgroundColor: '#f5f5f5' }}>

            <Title level={1} style={{ textAlign: 'center', marginBottom: '0px', paddingTop: '20px', fontFamily: 'Montserrat', fontWeight: '600' }}>
                Contact Us
            </Title>
            <div
                style={{
                    minHeight: '100vh',
                    backgroundColor: '#f5f5f5',
                    display: 'flex',
                    margin: '0 auto',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}
            >
                <Card
                    style={{
                        width: '100%',
                        maxWidth: 1000,
                        boxShadow: '0 8px 24px rgba(0,0,0,0.2)',
                        borderRadius: 16,
                        overflow: 'hidden'
                    }}
                >
                    <Row gutter={[32, 32]} align="middle">
                        {/* Left Side - Company Details */}
                        <Col xs={24} md={12}>
                            <div style={{
                                background: 'linear-gradient(135deg, #9b2231 0%, #2b2b2b 100%)',
                                color: 'white',
                                padding: '2rem',
                                borderRadius: 16,
                                height: '100%'
                            }}>
                                <Title level={2} style={{ color: 'white', marginBottom: '1rem', fontFamily: 'Montserrat' }}>
                                    Gulf Development Contracting
                                </Title>

                                <Paragraph style={{ color: 'rgba(255,255,255,0.8)', fontSize: '1rem' }}>
                                    We are a premier recruitment agency bridging the gap between exceptional talent and top-tier organizations across diverse industries.
                                    Combining the expertise of a global network with the adaptability of a local partner,
                                    we deliver tailored solutions to meet the evolving needs of businesses and professionals.
                                </Paragraph>

                                <Divider style={{ backgroundColor: 'rgba(255,255,255,0.2)' }} />

                                <Space direction="vertical" size="large">
                                    <div>
                                        <Text strong style={{ color: 'white', display: 'block', marginBottom: '0.5rem' }}>
                                            <HomeOutlined style={{ marginRight: 8 }} />
                                            Office Address
                                        </Text>
                                        <Text style={{ color: 'rgba(255,255,255,0.8)' }}>
                                            Rossais Tower, Ahmad Al Hawari street, Al Murabba, Riyadh, KSA
                                        </Text>
                                    </div>

                                    <div>
                                        <Text strong style={{ color: 'white', display: 'block', marginBottom: '0.5rem' }}>
                                            <PhoneOutlined style={{ marginRight: 8 }} />
                                            Contact Numbers
                                        </Text>
                                        <Text style={{ color: 'rgba(255,255,255,0.8)' }}>
                                            +966 565817074
                                        </Text>
                                    </div>

                                    <div>
                                        <Text strong style={{ color: 'white', display: 'block', marginBottom: '0.5rem' }}>
                                            <MailOutlined style={{ marginRight: 8 }} />
                                            Email Address
                                        </Text>
                                        <Text style={{ color: 'rgba(255,255,255,0.8)' }}>
                                            <a href='mailto:info@gulfdevelopment.net' style={{ color: 'rgba(255,255,255,0.8)' }} > info@gulfdevelopment.net </a>
                                        </Text>
                                    </div>
                                </Space>

                                <Divider style={{ backgroundColor: 'rgba(255,255,255,0.2)' }} />

                                <Space size="large">
                                    <a href="https://www.linkedin.com/company/gulf-development/" target="_blank" rel="noopener noreferrer">
                                        <LinkedinOutlined style={{ fontSize: '1.5rem', color: 'white' }} />
                                    </a>
                                    <a href="#" target="_blank" rel="noopener noreferrer">
                                        <TwitterOutlined style={{ fontSize: '1.5rem', color: 'white' }} />
                                    </a>
                                    <a href="#" target="_blank" rel="noopener noreferrer">
                                        <InstagramOutlined style={{ fontSize: '1.5rem', color: 'white' }} />
                                    </a>
                                </Space>
                            </div>
                        </Col>

                        {/* Right Side - Location & Map Placeholder */}
                        <Col xs={24} md={12}>
                            <div
                                style={{
                                    width: '100%',
                                    height: 500,
                                    backgroundColor: '#e0e0e0',
                                    borderRadius: 16,
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    overflow: 'hidden'
                                }}
                            >
                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1032.673348770138!2d46.75132344279296!3d24.627921709517672!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e2f0606c6618dd1%3A0xd0bccc867ced8704!2sOmar%20Al%20Hawari%2C%20Al%20Khalidiyyah%2C%20Riyadh%20Saudi%20Arabia!5e0!3m2!1sen!2suk!4v1733111823828!5m2!1sen!2suk" style={{ border: '0px' }} width="100%" height="100%" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                            </div>

                            <div style={{ marginTop: '1rem', textAlign: 'center' }}>
                                <Title level={4}>Our Location</Title>
                                <Text type="secondary">
                                    Strategically located in the heart of Riyadh, Saudi Arabia
                                </Text>
                            </div>
                        </Col>
                    </Row>
                </Card>
            </div>
        </div>
    );
};

export default ContactUsPage;