import React, { useState } from 'react';
import {
    Drawer,
    List,
    Avatar,
    Divider
} from 'antd';
import {
    MenuOutlined,
    UserOutlined,
    SettingOutlined,
    ProjectOutlined,
    LogoutOutlined,
    DashboardOutlined
} from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import Interviews from './Interviews';


// Constants
const PRIMARY_COLOR = '#1890ff';
const SECONDARY_COLOR = '#001529';
const HOVER_COLOR = '#f0f0f0';

const ModernNavDrawer = () => {
    const [visible, setVisible] = useState(false);
    const navigate = useNavigate();

    const showDrawer = () => {
        setVisible(true);
    };

    const onClose = () => {
        setVisible(false);
    };

    const handleLogout = () => {
        localStorage.removeItem('token');
        navigate('/admin');
    };

    // Navigation Items
    const navItems = [
        {
            title: 'Dashboard',
            icon: <DashboardOutlined />,
            onClick: () => navigate('/admin/dashboard')
        },
        {
            title: 'Scheduled Interviews',
            icon: <ProjectOutlined />,
            onClick: () => navigate('/admin/interviews')
        },
        {
            title: 'Profile & Settings',
            icon: <SettingOutlined />,
            onClick: () => navigate('/admin/profile')
        },
        {
            title: 'Logout',
            icon: <LogoutOutlined />,
            onClick: handleLogout
        }
    ];

    return (
        <>
            {/* Hamburger Button */}
            <div
                style={{
                    padding: '10px',
                    position: 'fixed',
                    top: '10px',
                    left: '10px',
                    zIndex: 1000,
                    background: '#fff',
                    borderRadius: '4px',
                    boxShadow: '0 2px 8px rgba(0,0,0,0.15)',
                    cursor: 'pointer'
                }}
                onClick={showDrawer}
            >
                <MenuOutlined style={{ fontSize: '20px', color: SECONDARY_COLOR }} />
            </div>

            {/* Drawer Sidebar */}
            <Drawer
                title={
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '10px'
                    }}>
                        <Avatar
                            style={{
                                backgroundColor: PRIMARY_COLOR,
                                verticalAlign: 'middle',
                            }}
                            size="large"
                            icon={<UserOutlined />}
                        />
                        <div>
                            <h3 style={{
                                margin: 0,
                                color: SECONDARY_COLOR
                            }}>
                                Admin Panel
                            </h3>
                            <p style={{
                                margin: 0,
                                fontSize: '12px',
                                color: '#666'
                            }}>
                                Welcome back!
                            </p>
                        </div>
                    </div>
                }
                placement="left"
                onClose={onClose}
                visible={visible}
                width={280}
                bodyStyle={{
                    padding: 0
                }}
            >
                <List
                    itemLayout="horizontal"
                    dataSource={navItems}
                    renderItem={item => (
                        <List.Item
                            onClick={() => {
                                item.onClick();
                                setVisible(false);
                            }}
                            style={{
                                cursor: 'pointer',
                                padding: '15px 20px',
                                transition: 'background-color 0.3s',
                            }}
                            className="nav-item"
                        >
                            <List.Item.Meta
                                avatar={React.cloneElement(item.icon, {
                                    style: {
                                        fontSize: '20px',
                                        color: SECONDARY_COLOR
                                    }
                                })}
                                title={
                                    <span style={{
                                        color: SECONDARY_COLOR,
                                        fontWeight: 500
                                    }}>
                                        {item.title}
                                    </span>
                                }
                            />
                        </List.Item>
                    )}
                />

                <Divider style={{ margin: '10px 0' }} />

                <div
                    style={{
                        padding: '20px',
                        position: 'absolute',
                        bottom: 0,
                        width: '100%',
                        borderTop: '1px solid #f0f0f0'
                    }}
                >
                    <p
                        style={{
                            margin: 0,
                            textAlign: 'center',
                            color: '#999',
                            fontSize: '12px'
                        }}
                    >
                        2024 GDC Admin Panel
                    </p>
                </div>
            </Drawer>
        </>
    );
};

export default ModernNavDrawer;