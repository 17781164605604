import React, { useState } from 'react';
import {
    Card,
    Form,
    Input,
    Button,
    message,
    Typography
} from 'antd';
import {
    LockOutlined,
    UserOutlined,
    KeyOutlined
} from '@ant-design/icons';

import { apiBaseUrl } from '../../constants/apiDetails';
import { useNavigate } from 'react-router-dom';
import { isExpired, decodeToken, useJwt } from 'react-jwt';

const { Title } = Typography;

const AdminLogin = () => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const { useEffect } = React;

    // Check if user is already logged in
    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token && !isExpired(token)) {
            navigate("/admin/dashboard");
        }
    }, [navigate]);

    // Color configuration
    const PRIMARY_COLOR = "#9b2231";
    const SECONDARY_COLOR = "#2b2b2b";

    // Handle forgot password
    const handleForgotPassword = () => {
        const username = form.getFieldValue('username');

        if (!username) {
            message.error('Username is required to reset password', 2);
            return;
        } else {
            const api_url = `${apiBaseUrl}/reset-admin-password`;
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ username })
            };

            fetch(api_url, requestOptions).then(
                response => response.json()
            ).then(
                data => {
                    console.log(data);
                    if (data.status === "success") {
                        message.success('Password reset request sent', 2);
                    }
                    else {
                        message.error('An error occurred. Please try again later.', 2);
                    }
                }
            ).catch(
                error => {
                    console.error(error);
                    message.error('An error occurred. Please try again later.', 2);
                }
            );
        }
    };

    // Login submission handler
    const onFinish = (values) => {
        setLoading(true);
        const { username, password } = values;
        const db_url = `${apiBaseUrl}/admin-login`;
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ username, password })
        };

        fetch(db_url, requestOptions).then(
            response => response.json()
        ).then(
            data => {
                console.log(data);
                if (data.status === "success") {
                    message.success('Login successful!', 2);
                    console.log(data.token);
                    localStorage.setItem('token', data.token);
                    navigate("/admin/dashboard");
                }
                else {
                    message.error('Invalid credentials! Please Try Again or Reset your Password', 2);
                }
            }
        ).catch(
            error => {
                console.error(error);
                message.error('An error occurred. Please try again later.', 2);
            }
        ).finally(
            () => setLoading(false)
        );
    };

    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
                backgroundColor: 'white'
            }}
        >
            <Card
                style={{
                    width: 350,
                    boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
                    borderRadius: '8px'
                }}
            >
                <div style={{ textAlign: 'center', marginBottom: 24 }}>
                    <Title level={3} style={{ color: PRIMARY_COLOR, fontFamily: "Montserrat" }}>GDC ADMIN LOGIN</Title>
                </div>

                <Form
                    form={form}
                    onFinish={onFinish}
                >
                    {/* Username Field */}
                    <Form.Item
                        name="username"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your Username!'
                            }
                        ]}
                    >
                        <Input
                            prefix={<UserOutlined style={{ color: SECONDARY_COLOR }} />}
                            placeholder="Username"
                            style={{
                                height: 40,
                                borderColor: SECONDARY_COLOR
                            }}
                        />
                    </Form.Item>

                    {/* Password Field */}
                    <Form.Item
                        name="password"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your Password!'
                            }
                        ]}
                    >
                        <Input.Password
                            prefix={<LockOutlined style={{ color: SECONDARY_COLOR }} />}
                            placeholder="Password"
                            style={{
                                height: 40,
                                borderColor: SECONDARY_COLOR
                            }}
                        />
                    </Form.Item>

                    {/* Forgot Password Link */}
                    <Form.Item>
                        <Button
                            type="link"
                            onClick={handleForgotPassword}
                            icon={<KeyOutlined />}
                            style={{
                                color: PRIMARY_COLOR,
                                padding: 0
                            }}
                        >
                            Reset Password
                        </Button>
                    </Form.Item>

                    {/* Login Button */}
                    <Form.Item>
                        <Button
                            type="primary"
                            htmlType="submit"
                            loading={loading}
                            block
                            style={{
                                backgroundColor: PRIMARY_COLOR,
                                borderColor: PRIMARY_COLOR,
                                height: 40
                            }}
                        >
                            Login
                        </Button>
                    </Form.Item>
                </Form>
            </Card>
        </div>
    );
};

export default AdminLogin;