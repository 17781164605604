import React from 'react';
import { Layout, Typography, Button, Row, Col, Image } from 'antd';
import { primaryColor, secondaryColor } from '../constants/colors';
import aboutUsImage from '../images/about-us.jpg';

const { Content } = Layout;
const { Title, Text } = Typography;

const AboutUs = () => {

    // Handle Apply Now button click
    const handleApplyNow = () => {
        const applyForm = document.getElementById('apply-form');
        if (applyForm) {
            applyForm.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    };


    return (
        <Layout id="about">
            <Content style={{ padding: '10px' }}>
                <Row
                    justify="center"
                    align="middle"
                    style={{
                        backgroundColor: secondaryColor,
                        color: 'white',
                        height: '100%',
                        boxShadow: '0 8px 15px rgba(0, 0, 0, 0.2)',
                    }}
                >
                    {/* Text Column - Smaller Size */}
                    <Col
                        xs={24}
                        md={10} // Text takes 10/24 width on medium screens and above
                        style={{
                            padding: '30px',
                            backgroundColor: secondaryColor,
                        }}
                    >
                        <Title
                            level={2}
                            style={{
                                color: 'white',
                                fontFamily: 'Montserrat, sans-serif',
                                fontWeight: 'bold',
                                fontSize: '45px',
                            }}
                        >
                            We've Been Building For Over 10 Years
                        </Title>
                        <div style={{ backgroundColor: primaryColor, width: '20%', height: '3px', marginBottom: '10px', borderRadius: '10px' }} />
                        <br />
                        <Text
                            style={{
                                color: 'white',
                                fontFamily: 'Montserrat, sans-serif',
                                fontSize: '15px',
                                display: 'block',
                                marginBottom: '20px',
                            }}
                        >
                            At Gulf Development, we are dedicated to providing innovative and strategic solutions for civil and electro-mechanical work across Saudi Arabia.
                        </Text>
                        <Text
                            style={{
                                color: 'white',
                                fontFamily: 'Montserrat, sans-serif',
                                fontSize: '15px',
                                display: 'block',
                                marginBottom: '20px',
                            }}
                        >
                            As a rapidly growing company, we take pride in approaching every challenge with confidence, insight, and a strong commitment to our clients.
                        </Text>
                        <Text
                            style={{
                                color: 'white',
                                fontFamily: 'Montserrat, sans-serif',
                                fontSize: '15px',
                                display: 'block',
                                marginBottom: '20px',
                            }}
                        >
                            We are passionate about the endless possibilities technology offers today and in the future, and this passion drives us to continuously innovate. As a forward-thinking business, we aim to lead in our industry and create lasting impact through our work.
                        </Text>
                        <Button
                            id='about-apply-button'
                            size="large"
                            style={{
                                backgroundColor: primaryColor,
                                color: 'white',
                                borderColor: primaryColor,
                                fontWeight: 'bold',
                            }}
                            onClick={handleApplyNow}
                        >
                            APPLY NOW
                        </Button>
                    </Col>
                    {/* Image Column - Larger Size */}
                    <Col
                        xs={24}
                        md={14}
                        style={{ height: '100%' }}
                    >
                        <Image
                            src={aboutUsImage}
                            alt="Gulf Development Contracting Construction Machinery"
                            preview={false}
                            style={{
                                width: '100%',
                                height: '100%',
                                objectFit: 'cover',
                                objectPosition: 'center',
                            }}
                        />
                    </Col>
                </Row>
            </Content>
        </Layout>
    );
};

export default AboutUs;
