import React from 'react';
import { Button, Typography } from 'antd';
import { motion } from 'framer-motion';
import bgImage from '../images/home-section.jpg';
import { primaryColor } from '../constants/colors';

const { Text, Title } = Typography;

const HomePage = () => {
    // Handle Apply Now button click
    const HandleClick = () => {
        const applyForm = document.getElementById('apply-form');
        if (applyForm) {
            applyForm.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    };

    // Animation Variants
    const containerVariants = {
        hidden: { opacity: 0 },
        visible: { opacity: 1, transition: { duration: 1 } },
    };

    const textVariants = {
        hidden: { y: 50, opacity: 0 },
        visible: { y: 0, opacity: 1, transition: { duration: 1, delay: 0.5 } },
    };

    const buttonVariants = {
        hidden: { scale: 0.8, opacity: 0 },
        visible: { scale: 1, opacity: 1, transition: { duration: 0.8, delay: 1 } },
    };

    return (
        <motion.div
            id="home"
            style={{
                backgroundImage: `url(${bgImage})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                backgroundAttachment: 'fixed',
                height: '100vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                color: 'white',
                position: 'relative',
            }}
            variants={containerVariants}
            initial="hidden"
            animate="visible"
        >
            <div
                style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(0, 0, 0, 0.6)',
                    zIndex: 1,
                }}
            />
            <motion.div
                style={{
                    zIndex: 2,
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    padding: '40px',
                    borderRadius: '10px',
                    maxWidth: '800px',
                    width: '100%',
                    textAlign: 'center',
                }}
                variants={textVariants}
            >
                <motion.div variants={textVariants}>
                    <Title
                        style={{
                            fontFamily: 'Montserrat, sans-serif',
                            fontWeight: 600,
                            backgroundColor: 'transparent',
                            color: 'white',
                        }}
                        level={1}
                    >
                        Gulf Development Contracting
                    </Title>
                </motion.div>
                <motion.div variants={textVariants}>
                    <Text
                        style={{
                            fontSize: '18px',
                            fontFamily: 'Montserrat, sans-serif',
                            textAlign: 'center',
                            backgroundColor: 'transparent',
                            color: 'white',
                        }}
                    >
                        We are a leading company in Saudi Arabia, offering both global and local services.
                        <br />
                        We combine the strength and expertise of international businesses with the flexibility and personal touch of a local company.
                        <br />
                        <br />
                        Join us and be part of a dynamic team that values growth, innovation, and the opportunity to make an impact.
                    </Text>
                </motion.div>
                <br />
                <br />
                <motion.div variants={buttonVariants}>
                    <Button
                        onClick={() => HandleClick()}
                        style={{
                            backgroundColor: 'white',
                            color: primaryColor,
                            borderRadius: '30px',
                            padding: '10px 20px',
                            border: 'none',
                            cursor: 'pointer',
                            fontSize: '16px',
                            fontWeight: 'bold',
                            fontFamily: 'Montserrat, sans-serif',
                        }}
                    >
                        Apply Now
                    </Button>
                </motion.div>
            </motion.div>
        </motion.div>
    );
};

export default HomePage;